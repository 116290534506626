import React, { useEffect, useState } from "react";
import ToggleButton from "../../components/app/ToggleButton";
import { Grid } from "@material-ui/core";
import { displayProductQtyFieldBasedOnProductUnitValue, ProductSelectorProps } from "./ProductSelector";
import { getMIUIGridScale, ProductGridType } from "../../types/UserInterface";
import { ProductInputLabel } from "./ProductInputLabel";
import { ProductUnit } from "../../constants/product";

const ProductTypeServiceSelector: React.FC<ProductSelectorProps> = (props: ProductSelectorProps) => {
    const { id, product, selected, gridType, qty } = props;
    const isSmallGrid = gridType === ProductGridType.SmallGrid;
    const isList = gridType === ProductGridType.List;
    const [qtyValue, setQtyValue] = useState(qty);

    useEffect(() => {
        setQtyValue(qty);
    }, [qty]);

    const handleToggle = (): void => {
        const { onToggle, product } = props;
        onToggle(product.id);
    };

    const setInputValue = (value: any): void => {
        const { onChange, product } = props;
        setQtyValue(value);
        onChange(product.id, value);
    };

    const displayLabel = product.unit === ProductUnit.WHOLE.key || product.unit === ProductUnit.PARTIAL.key;

    return (
        <Grid
            key={`key-en-${id}`}
            item
            id={`feature-enabled-${id}`}
            {...getMIUIGridScale(gridType)}
            className={`pos-item pos-item-v2 ${selected ? "is-active" : ""}`}
        >
            <ToggleButton isActive={selected} onClick={handleToggle}>
                <div className={`newPos-wrapper newPos-wrapper-v2 ${isList ? "newPos-wrapper__list" : ""}`}>
                    <span className={`title-wrap title-wrap-v2 ${isSmallGrid ? "title-wrap__small-grid" : ""} ${isList ? "title-wrap__list" : ""}`}>
                        {product.name}
                    </span>
                    <span className={`price-wrap price-wrap-v2 ${isList ? "price-wrap__list" : ""}`}>
                        <span className={`price price-v2 ${isList ? "price__list" : ""}`}>${product.cost}</span>
                    </span>
                    {selected && (
                        <>
                            <div className="display-label-wrap">
                                {displayLabel && <ProductInputLabel>Hours</ProductInputLabel>}
                                <span className="price-inp-wrap price-inp-wrap-amount amount_wrap">
                                    {displayProductQtyFieldBasedOnProductUnitValue(product, setInputValue, id, qtyValue)}
                                </span>
                            </div>
                        </>
                    )}
                </div>
            </ToggleButton>
        </Grid>
    );
};

export default ProductTypeServiceSelector;

