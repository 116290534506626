import { Location } from '../../types/Location';
import { get } from 'lodash';
import { isClientSupport, isEmployee, isSuperAdmin, isRSImplementation, isRSEmployee } from './auth';
import { User } from '../../types/User';
import { Shift } from "../../types/Shift";
import { Department } from "../../types/Department";
import { LocationsState } from '../../reducers/locations';
import { ShiftsState } from '../../reducers/shifts';
import { DepartmentsState } from '../../reducers/departments';

export function getDepartmentsByLocationId(location: Location, departments: DepartmentsState): Department[] | undefined {
  // eslint-disable-next-line
  // @ts-ignore
  return location?.departments?.map(id => departments?.data?.[String(id)]) as any;
}

export function getShiftsByLocationId(
  location: Location,
  shifts: ShiftsState,
): Shift[] {
  return location?.shifts?.map(id => shifts?.data?.[String(id)]).filter(shift => !!shift) as any;
}

export interface PreSelectedLocationValue {
  locationId?: string;
  departmentId?: string;
  shiftId?: string;
}

// eslint-disable-next-line
export function getPreselectedLocationValuesIfOnlyOneOptionAvailable(
  appSettings: { accessibleLocations?: string[] },
  locations: LocationsState,
  shifts: ShiftsState,
  departments: DepartmentsState,
  companyId: string,
  me?: User
): PreSelectedLocationValue {
  const locationData = me && isEmployee(me) && isClientSupport(me)
    ? getLocationsByIds(locations, me.companyId, me.locationIds)
    : getLocationsArrayFromSettings(appSettings, locations, companyId);

  const preselectedOptions: PreSelectedLocationValue = {};
  if (locationData?.length === 1) {
    preselectedOptions.locationId = locationData[0].id;

    const location = get(locations.data, locationData[0].id);
    const departmentsList = location && getDepartmentsByLocationId(location, departments);
    const shiftsList = location && getShiftsByLocationId(location, shifts);
    if (departmentsList?.length === 1) {
      preselectedOptions.departmentId = departmentsList[0].id;
    }
    if (shiftsList?.length === 1) {
      preselectedOptions.shiftId = shiftsList[0].id;
    }
  }
  return preselectedOptions;
}

export function getLocationsArrayFromSettings(
  settings: { accessibleLocations?: string[] },
  locations: LocationsState,
  companyId: string
): Location[] {
  return (settings?.accessibleLocations || [])
    .map(id => locations.data?.[id])
    .filter((item) => item?.companyId === companyId)
    .filter((item) => !item?.isDeactivated) as any;
}

export function getLocationsByIds(
  locations: LocationsState,
  companyId: string,
  locationIds: string[] = [],
): Location[] {
  return locationIds
    .map(id => locations.data?.[id])
    .filter((item) => item?.companyId === companyId)
    .filter((item) => !item?.isDeactivated) as any;
}

export function canCreateLocation(user?: User): boolean {
  return isSuperAdmin(user) || isRSImplementation(user) || isRSEmployee(user);
}
