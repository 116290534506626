import React from 'react';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { IconButton } from "../ui/Buttons";
import { P, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@roadsync/roadsync-ui';
import useStyles from "./PaymentMethodListButton.css";

interface Props {
  text?: React.ReactNode;
  icon: React.ReactElement;
  onClick?: () => void;
  id?: string;
}

const PaymentMethodListButton: React.FC<Props> = (props: Props): React.ReactElement => {
  const { text, icon, onClick, id, ...rest } = props;
  const classes = useStyles();
  return (
    <ListItem button className={classes.listItem} onClick={onClick} id={id} {...rest}>
      <ListItemAvatar>{icon}</ListItemAvatar>
      <ListItemText><P variant="h4">{text}</P></ListItemText>
      <ListItemSecondaryAction><IconButton><ArrowForwardIos /></IconButton></ListItemSecondaryAction>
    </ListItem>
  );
};

export default PaymentMethodListButton;
