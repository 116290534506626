import React from "react";
import { connect } from "react-redux";
import { AuthState } from "./reducers/auth";
import { GlobalState } from "./types";

interface OwnProps {
    ignoreFeatureFlag?: boolean;
}

interface StateProps {
    auth: AuthState;
}

type Props = OwnProps & StateProps;

interface State {
    hubspotScriptSource: string;
}

class HubSpotLoader extends React.Component<Props, State> {
    scriptTagId = "hs-script-loader";

    constructor(props: Props) {
        super(props);
        this.state = {
            hubspotScriptSource: "https://js.hs-scripts.com/2427383.js"
        };
    }

    async componentDidUpdate() {
        const { auth } = this.props;
        const isUserLoggedIn = Boolean(auth?.me?.id);
        const isScriptAlreadyExists = Boolean(document.querySelector("#" + this.scriptTagId));

        if (!isUserLoggedIn) {
            return;
        }

        if (isScriptAlreadyExists) {
            return;
        }

        const scriptNode = document.createElement("script");
        scriptNode.setAttribute("src", this.state.hubspotScriptSource);
        scriptNode.setAttribute("id", this.scriptTagId);
        document.head.appendChild(scriptNode);
    }

    render(): React.ReactNode {
        return <></>;
    }
}

const propsFromState = ({ auth }: GlobalState) => ({ auth });

export default connect(propsFromState)(HubSpotLoader);
