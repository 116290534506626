import { createStore, applyMiddleware, AnyAction } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import rootReducer from '../reducers';
import * as storage from '../services/app/storage';

export type RootState = ReturnType<typeof rootReducer>;
type RootAction = AnyAction;

const APP_SETTINGS_DEFAULTS = 'appSettings.defaults';

export const configureStore = function (initialState: {} = {}) {
    const preloadedState: RootState = {
        appSettings: {
            loading: true,
            defaults: storage.getItem(APP_SETTINGS_DEFAULTS),
        },
        ...initialState,
    } as RootState;

    const store = createStore(rootReducer, preloadedState, composeWithDevTools(
        applyMiddleware(
            thunkMiddleware as ThunkMiddleware<RootState, RootAction>,
        )
    ));

    store.subscribe(() => {
        const state = store.getState() as any;
        const appSettings = state && state.appSettings;

        if (appSettings && appSettings.defaults) {
            storage.setItem(APP_SETTINGS_DEFAULTS, appSettings.defaults);
        } else {
            storage.removeItem(APP_SETTINGS_DEFAULTS);
        }

        if(process.env.NODE_ENV !== "production") {
            localStorage.setItem('reduxState', JSON.stringify(state));
        }
    });

    return store;
};
