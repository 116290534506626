import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { InvoicePaths } from '../../../../services/app/paths';
import { depositCash } from '../../../../actions/deposits';
import CashDetailsFormV2 from '../../../../components/invoice/CashDetailsFormV2';
import { Invoice } from '../../../../types/Invoice';
import { isConfirmationBeforeSendingEnabled, isSignatureEnabled } from '../../../../services/app/company';
import { Company } from '../../../../types/Company';
import { clearSubmitErrors, SubmissionError } from 'redux-form';
import { ModalsConstants } from '../../../../constants/modals';
import { PaymentMethods } from '../../../../constants/invoice';
import { openModal } from '../../../../actions/modals';
import { showErrorAlert } from '../../../../actions/alerts';
import { P } from '@roadsync/roadsync-ui';
import { StyledComponentProps, Theme, withStyles } from '@material-ui/core';
import InvoiceFinalizeContainer from '../../../../components/invoice/InvoiceFinalizeContainer';
import { GlobalState } from '../../../../types/GlobalState';
import { InvoicePreparationStepProps } from '../../../../types/InvoicePreparationStepProps';
import { payButtonPressed } from '../../../../services/api/invoices';

const styles = (theme: Theme): {} => ({
    customerName: {
        fontWeight: "normal",
        marginBottom: theme.spacing(2),
    },
});

type PropsFromState = Pick<GlobalState, "invoices" | "companies"> & {
    signatureEnabled?: boolean;
    isConfirmationEnabled?: boolean;
}

interface State {
    signatureUrl?: string;
    disableSubmitButton?: boolean;
}

interface RouteParams {
    invoiceId: string;
}

interface Props extends PropsFromState, RouteComponentProps<RouteParams>, DispatchProp, InvoicePreparationStepProps, StyledComponentProps {
}

class CashDetails extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.openAddSignatureModal = this.openAddSignatureModal.bind(this);
        this.sendInvoice = this.sendInvoice.bind(this);
        this.openConfirmationModal = this.openConfirmationModal.bind(this);
        this.signatureUploaded = this.signatureUploaded.bind(this);
        this.state = {};
    }

    signatureUploaded(signatureUrl: string): void {
        const { dispatch } = this.props;
        dispatch(clearSubmitErrors('cashDetailsForm'));
        this.setState({ signatureUrl });
    }

    openConfirmationModal(): void {
        const { dispatch } = this.props;
        const invoice = this.getInvoice();
        dispatch(openModal(ModalsConstants.CONFIRM_INVOICE_TOTAL, {
            carrier: invoice?.payerName,
            price: invoice?.grandTotal,
            invoiceType: { display: this.getInvoiceTypeName(invoice) },
            handleInvoiceSubmit: this.handleSubmit,
        }));
    }

    getInvoiceTypeName(invoice?: Invoice): string {
        return invoice?.type ? PaymentMethods.getByKey(invoice.type).display : '';
    }

    hasSignature(): boolean {
        return !!this.state.signatureUrl;
    }

    getInvoice(): Invoice | undefined {
        const { invoices } = this.props;
        return invoices.data?.[this.getInvoiceId()];
    }

    getCompany(): Company | undefined {
        const { companies } = this.props;
        const invoice = this.getInvoice();
        return "string" === typeof invoice?.company
            ? companies?.data?.[invoice.company]
            : invoice?.company;
    }

    getSubmitAction(): Promise<void> {
        const { dispatch } = this.props;
        const invoice = this.getInvoice();
        const invoiceId = this.getInvoiceId();
        return dispatch<any>(depositCash(invoiceId, invoice?.type as string));
    }

    async handleSubmit(): Promise<void | SubmissionError<{}, string>> {
        const { history, dispatch } = this.props;
        try {
            this.setState({ disableSubmitButton: true });
            payButtonPressed(this.getInvoice());
            await this.getSubmitAction();
            history.push(InvoicePaths.listUrl());
        }
        catch (e) {
            dispatch(showErrorAlert((e as any)?.message));
        } finally {
            this.setState({ disableSubmitButton: false });
        }
    }

    async sendInvoice(): Promise<void> {
        const signatureEnabled = this.isSignatureEnabled();
        const isConfirmationEnabled = this.isConfirmationEnabled();
        if (signatureEnabled && !this.hasSignature()) {
            throw new SubmissionError({ _error: 'Please add your signature to continue' });
        }
        if (isConfirmationEnabled) {
            this.openConfirmationModal();
            return;
        }
        this.handleSubmit();
    }

    getInvoiceId(): string {
        const { match: { params: { invoiceId } } } = this.props;
        return invoiceId;
    }

    openAddSignatureModal(): void {
        const { dispatch } = this.props;
        dispatch(openModal(ModalsConstants.SIGNATURE, {
            invoiceId: this.getInvoiceId(),
            signatureUploaded: this.signatureUploaded,
        }));
    }

    isSignatureEnabled(): boolean {
        return isSignatureEnabled(this.getCompany());
    }

    isConfirmationEnabled(): boolean {
        return isConfirmationBeforeSendingEnabled(this.getCompany());
    }

    // eslint-disable-next-line
    render(): React.ReactElement {
        const { onCompletedStep } = this.props;
        const { disableSubmitButton } = this.state;
        const invoice = this.getInvoice();
        return (
            <InvoiceFinalizeContainer formName="cashDetailsForm" onCompletedStep={onCompletedStep} disableSubmitButton={disableSubmitButton}>
                <CashDetailsFormV2
                    onSubmit={this.sendInvoice}
                    signatureEnabled={this.isSignatureEnabled()}
                    signatureUrl={this.state.signatureUrl}
                    openAddSignatureModal={this.openAddSignatureModal}
                >
                    {invoice?.type &&
                        <P variant="h4" gutterBottom>
                            Please complete invoice to confirm this transaction will be
                            paid {invoice.type === PaymentMethods.CASH.key ? "in cash" : `via ${PaymentMethods.getByKey(invoice.type).display}`}.
                        </P>
                    }
                </CashDetailsFormV2>
            </InvoiceFinalizeContainer>
        );
    }
}

// istanbul ignore next
const mapStateToProps = ({ invoices, companies }: GlobalState): PropsFromState => ({ invoices, companies });
export default withStyles(styles)(withRouter(connect(mapStateToProps)(CashDetails)));
