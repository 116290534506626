import React from "react";
import { PaymentMethods } from "../../constants/invoice";
import { WithInvoiceProps } from "../ui/WithInvoiceProps";
import { CheckTypes } from "../../constants/deposit";
import useStyles from "./InvoiceTypeLabel.css";

const InvoiceTypeLabel: React.FC<WithInvoiceProps> = (props: WithInvoiceProps): React.ReactElement => {
    const { invoice } = props;
    const initialType = PaymentMethods.getByKey(invoice.initialType);
    const type = PaymentMethods.getByKey(invoice.type);
    const classes = useStyles();
    const isComdataExpressCode = invoice.subtype === CheckTypes.COMDATA_EXPRESS_CODE.key;
    const showInitialType = initialType && initialType !== type;
    return (
        <>
            {showInitialType && <span className={classes.span}>{initialType?.display}</span>}
            <span className={classes.span}>
                {isComdataExpressCode && <>{showInitialType && <> / </>}Comdata Express Code</>}
                {!isComdataExpressCode &&
                    <>{type?.display}{invoice.subtype && <> / {CheckTypes.getByKey(invoice.subtype)?.display}</>}</>
                }
            </span>
        </>
    );
}

export default React.memo(InvoiceTypeLabel);
