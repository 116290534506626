import { Survey } from '../components/survey/Survey';
import { createUserSurvey, updateSurveyById } from '../components/survey/SurveyAPI';
import { AlertActions } from '../constants/actions';
import { AlertSeverity } from '../constants/AlertSeverity';
import { setWexTermsConditions } from '../services/api/features';
import { getUser } from '../services/api/users';
import { Log } from '../services/LoggerService';

export interface AlertProps {
  severity?: AlertSeverity;
  text: string;
  id?: string;
  linkText?: string;
  linkURL?: string;
  linkOnClick?: () => void;
  onCloseText?: string;
  onClose?: () => void;
  autoHideDuration?: number | null;
}

interface AlertAction {
  type: string;
  alert?: Partial<AlertProps>;
}

export const showAlert = (alert: AlertProps): AlertAction => ({ type: AlertActions.ALERT_SHOW, alert });

export const showSuccessAlert = (text: string, params: Partial<AlertProps> = {}): (f: Function) => Function =>
  (dispatch: Function): Function => dispatch(showAlert({ severity: AlertSeverity.Success, text, ...params }));

export const showPendingAlert = (text: string, params: Partial<AlertProps> = {}): (f: Function) => Function =>
  (dispatch: Function): Function => dispatch(showAlert({ severity: AlertSeverity.Warning, text, ...params }));

export const showErrorAlert = (
  text = "Something went wrong. Please try again later",
  params: Partial<AlertProps> = {}
): AlertAction => ({ type: AlertActions.ALERT_SHOW, alert: { severity: AlertSeverity.Error, text: text, ...params } });

export const showActionAlert = (text: string, params: Partial<AlertProps> = {}): (f: Function) => Function =>
  (dispatch: Function): Function => dispatch(showAlert({ severity: AlertSeverity.Info, text, ...params }));

export const dismissAlert: (id?: string) => AlertAction = (): AlertAction =>
  ({ type: AlertActions.ALERT_DISMISS });

export const showSurveyAlert = (survey: Survey, userId?: string): (f: Function) => Function => {

  const onDone = (answered = false): () => void => async (): Promise<void> => {
    if (!userId) return;
    try {
      const user = await getUser(userId);
      await setWexTermsConditions(user.companyId);
      const userSurvey = await createUserSurvey(userId, { answered, ignored: !answered });
      await updateSurveyById(userSurvey.id, { answered, ignored: !answered });
    } catch (e) {
      Log.captureException(e);
    }
  };

  return (dispatch: Function): Function => dispatch(showActionAlert(survey.popupText, {
    autoHideDuration: null,
    linkText: survey.linkText,
    linkURL: survey.surveyCode,
    linkOnClick: onDone(true),
    onClose: onDone(),
  }));
}
