import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { InvoicePaths } from '../../../../services/app/paths';
import { depositCheckCreateOrReuse, getDepositForInvoice, processCheck, processCheckComdata } from '../../../../actions/deposits';
import CheckDetailsForm, { CheckDetailsFormData } from '../../../../components/invoice/CheckDetailsForm';
import { Deposit, Invoice, CustomField, GlobalState, InvoicePreparationStepProps } from '../../../../types';
import { CheckTypes as Types, DepositStatuses } from '../../../../constants/deposit';
import { isConfirmationBeforeSendingEnabled, isReducePaperCheckFlowEnabled, isSignatureEnabled } from '../../../../services/app/company';
import { openModal } from '../../../../actions/modals';
import { ModalsConstants } from '../../../../constants/modals';
import { clearSubmitErrors, SubmissionError } from 'redux-form';
import { PaymentMethods, PreparationSteps } from '../../../../constants/invoice';
import { showErrorAlert, showSuccessAlert } from '../../../../actions/alerts';
import { formatDollar } from '../../../../services/app/formats';
import InvoiceFinalizeContainer from '../../../../components/invoice/InvoiceFinalizeContainer';
import InvoicePreparationStepContainer from './InvoicePreparationStepContainer';
import { P, Grid, LoadingContainer } from '@roadsync/roadsync-ui';
import { NoCheckRefundsText } from '../../../../components/ui/NoCheckRefundsText';
import CheckDetailsFormComdata, { CheckDetailsFormComdataData } from "../../../../components/invoice/CheckDetailsFormComdata";
import { Company } from "../../../../types";
import CheckDetailsFormComdataFallback from "../../../../components/invoice/CheckDetailsFormComdataFallback";
import { Paper } from "../../../../components/ui/Layout";
import { isComdataV2On as isComdataV2OnService } from '../../../../services/app/invoice';
import { isExpressCodeError } from '../../../../services/app/deposits';
import ComdataCheckFallbackText from '../../../../components/invoice/ComdataCheckFallbackText';
import { CreditCardProcessingModal } from './CreditCardProcessingModal';
import ComdataCheckFallbackReducePaperChecksText from '../../../../components/invoice/ComdataCheckFallbackReducePaperChecksText';
import ComdataInvalidDataModal from '../../../../components/check/ComdataInvalidDataModal';
import { InvoiceDeclineReasons } from '../../../../constants/invoiceDeclineReasons';
import { payButtonPressed } from '../../../../services/api/invoices';

interface State {
    signatureUrl?: string;
    loading: boolean;
    isComdataV2On?: boolean;
    isCodeInputedWasFleetCard?: boolean;
    comdataValidationError?: string;
    fallback?: boolean;
    disableSubmitButton?: boolean;
    showProcessingModal?: boolean;

    // Used to track the express code between workflows and fallbacks
    expressCode?: string;
    testIrrecoverable?: boolean;
    disableChargeBtnComdataV2?: boolean;
    isComdataInvalidDataModalOpen?: boolean;

    ComdataDriverNumberRequired: boolean;
    ComdataUnitNumberRequired: boolean;
    ComdataTripNumberRequired: boolean;
    payerFirstName?: string;
    payerLastName?: string;
    expressCodeHasBeenChanged: boolean;
}

interface RouteParams {
    invoiceId: string;
}

interface OwnProps {
    comdataApiLocationId?: string;
}

interface Props extends InvoicePreparationStepProps, RouteComponentProps<RouteParams>, DispatchProp, PropsFromState, OwnProps { }

class CheckDetails extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.openConfirmationModal = this.openConfirmationModal.bind(this);
        this.sendInvoice = this.sendInvoice.bind(this);
        this.signatureUploaded = this.signatureUploaded.bind(this);
        this.openAddSignatureModal = this.openAddSignatureModal.bind(this);
        this.handleSubmitComdata = this.handleSubmitComdata.bind(this);
        this.resetFieldInvalidState = this.resetFieldInvalidState.bind(this);
        this.resetDisableChargeBtnComdataV2 = this.resetDisableChargeBtnComdataV2.bind(this);
        this.isComdataApiFlowOn = this.isComdataApiFlowOn.bind(this);
        this.handleComdataInvalidDataModalClose = this.handleComdataInvalidDataModalClose.bind(this);
        this.changePaymentMethod = this.changePaymentMethod.bind(this);
        this.setFieldWasRequired = this.setFieldWasRequired.bind(this);
        this.resetRequiredValidationRules = this.resetRequiredValidationRules.bind(this);
        this.setPayerFirstName = this.setPayerFirstName.bind(this);
        this.setPayerLastName = this.setPayerLastName.bind(this);
        this.setExpressCodeHasBeenChanged = this.setExpressCodeHasBeenChanged.bind(this);
        this.state = {
            loading: true,
            fallback: false,
            ComdataDriverNumberRequired: false,
            ComdataUnitNumberRequired: false,
            ComdataTripNumberRequired: false,
            expressCodeHasBeenChanged: false,
        };
    }

    getDepositError(): string | undefined {
        const { deposit } = this.props;
        return deposit?.error && typeof (deposit?.error) === 'string' ? deposit.error : undefined;
    }

    async componentDidMount(): Promise<void> {
        const { invoice } = this.props;
        const isComdataV2On = isComdataV2OnService(invoice);
        const comdataValidationError = this.getDepositError();
        this.setState({
            loading: false,
            isComdataV2On,
            comdataValidationError,
            disableChargeBtnComdataV2: isComdataV2On && isExpressCodeError(comdataValidationError)
        });
        if (typeof comdataValidationError === 'string' && comdataValidationError.length > 0) this.setFieldWasRequired(comdataValidationError);
        isComdataV2On && !this.isRecoverableFlow() ? this.setState({ fallback: true }) : void (0);
    }

    getInvoiceId(): string {
        const { match: { params: { invoiceId } } } = this.props;
        return invoiceId;
    }

    getDeposit(): Deposit | undefined {
        return this.props.deposit;
    }

    getDepositOrThrow(): Deposit {
        const deposit = this.getDeposit();
        if (!deposit) {
            throw new Error("Check was not loaded properly. Please try again.");
        }
        return deposit;
    }

    async handleSubmit(values: CheckDetailsFormData): Promise<void> {
        const { dispatch, history, invoice } = this.props;
        try {
            payButtonPressed(invoice);
            const deposit = this.getDepositOrThrow();
            this.setState({ expressCode: values.expressCode, disableSubmitButton: true });
            await dispatch<any>(processCheck({ ...deposit, ...values }));
            history.push(InvoicePaths.listUrl());
        } catch (e) {
            dispatch(showErrorAlert((e as any)?.message));
        } finally {
            this.setState({ disableSubmitButton: false });
        }
    }

    openConfirmationModal(values: CheckDetailsFormData): void {
        const { dispatch, deposit, invoice } = this.props;
        dispatch(openModal(ModalsConstants.CONFIRM_INVOICE_TOTAL, {
            carrier: invoice?.payerName,
            price: invoice?.grandTotal,
            type: deposit?.checkType ? Types.getByKey(deposit.checkType) : undefined,
            invoiceType: invoice?.type ? PaymentMethods.getByKey(invoice.type) : undefined,
            checkNumber: deposit?.checkNumber || '',
            handleInvoiceSubmit: () => this.handleSubmit(values),
        }));
    }

    sendInvoice(values: CheckDetailsFormData): void {
        const { isConfirmationEnabled, signatureEnabled } = this.props;
        const { signatureUrl } = this.state;
        if (signatureEnabled && !signatureUrl) {
            throw new SubmissionError({ _error: 'Please add your signature to continue' });
        }

        if (isConfirmationEnabled) {
            this.openConfirmationModal(values);
            return;
        }
        this.handleSubmit(values);
    }

    getExpressCode(): string | undefined {
        const deposit = this.getDeposit();
        const { expressCode } = this.state;
        return expressCode ?? deposit?.expressCode?.toString();
    }

    getInitialValues(): Partial<CheckDetailsFormData> {
        const { deposit, invoice } = this.props;
        return {
            expressCode: this.getExpressCode(),
            driverNumber: deposit?.driverNumber,
            accountNumber: deposit?.accountNumber,
            trailerNumber: deposit?.trailerNumber,
            expirationDate: deposit?.expirationDate,
            tripNumber: deposit?.tripNumber,
            unitNumber: deposit?.unitNumber,
            checkType: deposit?.checkType,
            payerIdentifier: deposit?.payerIdentifier,
            payerState: deposit?.payerState,
            paymentError: invoice?.paymentError,
        }
    }

    signatureUploaded(signatureUrl: string): void {
        const { dispatch } = this.props;
        dispatch(clearSubmitErrors('checkDetails'));
        this.setState({ signatureUrl });
    }

    openAddSignatureModal(): void {
        const { dispatch } = this.props;
        dispatch(openModal(ModalsConstants.SIGNATURE, {
            invoiceId: this.getInvoiceId(),
            signatureUploaded: this.signatureUploaded,
        }));
    }

    isRecoverableFlow(): boolean {
        const deposit = this.getDeposit();
        return deposit?.checkNumber?.toString() === '999999' && deposit?.checkType === Types.COMCHEKV2.key;
    }

    async initFallbackFlow(): Promise<void> {
        // generate a comchek check number for the fallback scenario
        const { dispatch, invoice, deposit } = this.props;
        // use previously received values for fallback deposit
        const preparedDepositData = {
            expressCode: this.getExpressCode(),
            accountNumber: deposit?.accountNumber,
            tripNumber: deposit?.tripNumber,
            unitNumber: deposit?.unitNumber,
        };
        try {
            this.setState({ disableSubmitButton: true, showProcessingModal: true });
            await dispatch<any>(depositCheckCreateOrReuse(this.getInvoiceId(), Types.COMCHEK.key, invoice?.payerState, invoice?.payerIdentifier, preparedDepositData));
            this.setState({ fallback: true });
        } catch (e) {
            dispatch(showErrorAlert((e as any)?.message));
        } finally {
            this.setState({ disableSubmitButton: false, showProcessingModal: false });
        }
    }

    isCodeFleetCard(inputCode: string): boolean {
        const { reducePaperCheckFlowEnabled } = this.props;
        if (reducePaperCheckFlowEnabled) {
            return false;
        }

        const fleetCardBeginnings = ["556736", "560017", "556735", "555622"];
        for (const code of fleetCardBeginnings) {
            if (inputCode.indexOf(code) === 0)
                return true;
        }
        return false;
    }

    isCodeInputedWasFleetCard(): boolean {
        return Boolean(this.state.isCodeInputedWasFleetCard);
    }

    setFieldWasRequired(error: string): void {
        switch (error) {
            case InvoiceDeclineReasons.COMDATA_DRIVER_NUMBER_REQUIRED.key:
                this.setState({ ComdataDriverNumberRequired: true });
                break;
            case InvoiceDeclineReasons.COMDATA_UNIT_NUMBER_REQUIRED.key:
                this.setState({ ComdataUnitNumberRequired: true });
                break;
            case InvoiceDeclineReasons.COMDATA_TRIP_NUMBER_REQUIRED.key:
                this.setState({ ComdataTripNumberRequired: true });
                break;
            default: return;
        }
    }

    async handleSubmitComdata(values: CheckDetailsFormComdataData): Promise<void> {
        const { dispatch, history, invoice, signatureEnabled } = this.props;
        const { signatureUrl } = this.state;

        payButtonPressed(invoice);

        if (signatureEnabled && !signatureUrl) {
            throw new SubmissionError({ _error: 'Please add your signature to continue' });
        }

        const deposit = this.getDepositOrThrow();
        if (!values.expressCode) {
            return;
        }

        if (this.isCodeFleetCard(values.expressCode)) {
            this.initFallbackFlow();
            this.setState({ isCodeInputedWasFleetCard: true });
            return;
        }

        try {
            this.setState({ expressCode: values.expressCode, disableSubmitButton: true, showProcessingModal: true });
            this.setExpressCodeHasBeenChanged(false);
            const action = await dispatch<any>(processCheckComdata({ ...deposit, ...values }));
            if (action?.deposit?.status === DepositStatuses.AUTHORIZED && !action?.deposit?.error) {
                // new flow success -> redirect to invoice list
                dispatch<any>(showSuccessAlert('Check authorized successfully!'));
                history.push(InvoicePaths.listUrl());
                return;
            }

            // recoverable failure
            // response status 200 && deposit.error !== null deposit.status !== authorized -> another try
            const comdataValidationError = action?.deposit?.error;
            if (comdataValidationError) {
                this.setState({ comdataValidationError }); // set invalid state to to the form field
                this.setFieldWasRequired(comdataValidationError);
            }

            // TODO
        } catch (e) {
            if ((e as any)?.message === 'comdata.invalid_data') {
                dispatch<any>(getDepositForInvoice(this.getInvoiceId())).then(() => {
                    const comdataValidationError = this.getDepositOrThrow()?.error;
                    if (typeof comdataValidationError === 'string' && comdataValidationError.length > 0) {
                        this.setState({ comdataValidationError });
                        this.setFieldWasRequired(comdataValidationError);
                    }
                });

                this.setState({ isComdataInvalidDataModalOpen: true });
            }

            if ((e as any)?.message !== 'comdata.invalid_data') {
                // fallback flow
                this.setState({ testIrrecoverable: true });
                await this.initFallbackFlow();
            }
        } finally {
            this.setState({ disableSubmitButton: false, showProcessingModal: false });
        }
    }

    getInitialValuesComdataFallback(): Partial<CheckDetailsFormData> {
        const { deposit, invoice } = this.props;
        return {
            expressCode: this.getExpressCode(),
            driverNumber: deposit?.driverNumber,
            tripNumber: deposit?.tripNumber,
            unitNumber: deposit?.unitNumber,
            checkType: deposit?.checkType,
            payerIdentifier: deposit?.payerIdentifier,
            payerState: deposit?.payerState,
            paymentError: invoice?.paymentError,
        }
    }

    getInitialValuesComdata(): Partial<CheckDetailsFormComdataData> {
        const { deposit } = this.props;
        const { payerFirstName, payerLastName } = this.state;
        const paymentError = this.getDepositError();
        return {
            expressCode: this.getExpressCode(),
            driverNumber: deposit?.driverNumber,
            tripNumber: deposit?.tripNumber,
            unitNumber: deposit?.unitNumber,
            payerFirstName,
            payerLastName,
            paymentError,
        }
    }

    resetFieldInvalidState(e: React.ChangeEvent<HTMLInputElement>, errorKey?: string): void {
        if (errorKey === this.state.comdataValidationError) {
            this.setState({ comdataValidationError: undefined });
        }
    }

    getFormName(): string {
        const { fallback } = this.state;
        //using the isComdataApiFlowOn check instead of the isComdataV2On allows us to check if the location id has also been configured
        if (this.isComdataApiFlowOn() && fallback) {
            return 'checkDetailsComdataFallback';
        } else if (this.isComdataApiFlowOn()) {
            return 'checkDetailsComdata';
        }
        return "checkDetails";
    }

    isComdataApiFlowOn(): boolean {
        const { comdataApiLocationId } = this.props;
        const { isComdataV2On } = this.state;
        return !!isComdataV2On && !!comdataApiLocationId && comdataApiLocationId?.length > 0;
    }

    showComdataForm(): boolean {
        return this.isComdataApiFlowOn() && this.isRecoverableFlow() && !this.isCodeInputedWasFleetCard();
    }

    showComdataFallbackFlow(): boolean {
        return this.isComdataApiFlowOn() && !this.isRecoverableFlow() && !this.isCodeInputedWasFleetCard();
    }

    showComdataFleetCardFallbackFlow(): boolean {
        return this.isComdataApiFlowOn() && !this.isRecoverableFlow() && this.isCodeInputedWasFleetCard();
    }

    resetDisableChargeBtnComdataV2(): void {
        this.setState({ disableChargeBtnComdataV2: false });
    }

    getComdataCheckFallbackText(): React.ReactNode {
        const { deposit, reducePaperCheckFlowEnabled } = this.props;
        return reducePaperCheckFlowEnabled
            ? <ComdataCheckFallbackReducePaperChecksText />
            : <ComdataCheckFallbackText deposit={deposit} />;
    }

    handleComdataInvalidDataModalClose(): void {
        this.setState({ isComdataInvalidDataModalOpen: false });
    }

    changePaymentMethod(): void {
        const { onCompletedStep } = this.props;
        onCompletedStep(PreparationSteps.PAYMENT_METHOD);
    }

    shouldDisplayNoCheckRefundsText(): boolean {
        const { reducePaperCheckFlowEnabled } = this.props;

        // reduce flow fallback
        if (reducePaperCheckFlowEnabled && this.showComdataFallbackFlow()) return false;

        // regular flow + regular flow fallback
        // reduce flow
        // any other flow
        return true;
    }

    resetRequiredValidationRules(): void {
        this.setState({
            ComdataDriverNumberRequired: false,
            ComdataUnitNumberRequired: false,
            ComdataTripNumberRequired: false
        });
    }

    setPayerFirstName(value?: string): void {
        this.setState({
            payerFirstName: value
        });
    }

    setPayerLastName(value?: string): void {
        this.setState({
            payerLastName: value
        });
    }

    setExpressCodeHasBeenChanged(value: boolean): void {
        this.setState({
            expressCodeHasBeenChanged: value
        });
    }

    render(): React.ReactElement {
        const { signatureEnabled, invoice, onCompletedStep, deposit, reducePaperCheckFlowEnabled } = this.props;
        const {
            comdataValidationError, fallback, disableChargeBtnComdataV2, disableSubmitButton, showProcessingModal,
            isComdataInvalidDataModalOpen, expressCodeHasBeenChanged,
        } = this.state;
        const shouldDisplayNoCheckRefundsText = this.shouldDisplayNoCheckRefundsText();
        const initialValuesComdata = this.getInitialValuesComdata();
        //using the isComdataApiFlowOn check instead of the isComdataV2On allows us to check if the location id has also been configured
        return (
            <LoadingContainer loading={!deposit || !invoice}>
                <InvoiceFinalizeContainer
                    formName={this.getFormName()}
                    onCompletedStep={onCompletedStep}
                    fallback={this.isComdataApiFlowOn() && fallback}
                    disableChargeBtnComdataV2={this.isComdataApiFlowOn() && disableChargeBtnComdataV2}
                    disableSubmitButton={disableSubmitButton}
                >
                    <InvoicePreparationStepContainer>
                        <Grid container direction="column" spacing={2} wrap="nowrap">
                            {shouldDisplayNoCheckRefundsText && <Grid item><NoCheckRefundsText /></Grid>}
                            <Grid item>
                                {!this.isComdataApiFlowOn() &&
                                    <>
                                        <P variant="h4" gutterBottom>Please fill out the payment details below.</P>
                                        <CheckDetailsForm
                                            total={formatDollar(invoice?.grandTotal)}
                                            onSubmit={this.sendInvoice}
                                            initialValues={this.getInitialValues()}
                                            signatureEnabled={signatureEnabled}
                                            signatureUrl={this.state.signatureUrl}
                                            openAddSignatureModal={this.openAddSignatureModal}
                                        />
                                    </>
                                }
                                {this.showComdataForm() &&
                                    <>
                                        <P variant="h4" gutterBottom>Please provide all information available to the payer</P>
                                        <P gutterBottom>
                                            While some details might be required by Comdata, having complete data ensures a smooth process.
                                        </P>
                                        <P>&nbsp;</P>
                                        <CheckDetailsFormComdata
                                            onSubmit={this.handleSubmitComdata}
                                            initialValues={initialValuesComdata}
                                            resetFieldInvalidState={this.resetFieldInvalidState}
                                            comdataValidationError={comdataValidationError}
                                            resetDisableChargeBtnComdataV2={this.resetDisableChargeBtnComdataV2}
                                            signatureEnabled={signatureEnabled}
                                            signatureUrl={this.state.signatureUrl}
                                            openAddSignatureModal={this.openAddSignatureModal}
                                            fieldRequiredValidationRules={{
                                                ComdataDriverNumberRequired: this.state.ComdataDriverNumberRequired,
                                                ComdataUnitNumberRequired: this.state.ComdataUnitNumberRequired,
                                                ComdataTripNumberRequired: this.state.ComdataTripNumberRequired,
                                            }}
                                            resetRequiredValidationRules={this.resetRequiredValidationRules}
                                            setPayerFirstName={this.setPayerFirstName}
                                            setPayerLastName={this.setPayerLastName}
                                            setExpressCodeHasBeenChanged={this.setExpressCodeHasBeenChanged}
                                            expressCodeHasBeenChanged={expressCodeHasBeenChanged}
                                        />
                                        {reducePaperCheckFlowEnabled &&
                                            <ComdataInvalidDataModal
                                                open={isComdataInvalidDataModalOpen}
                                                onClose={this.handleComdataInvalidDataModalClose}
                                                onChangePaymentMethod={this.changePaymentMethod}
                                            />
                                        }
                                    </>
                                }
                                {this.showComdataFallbackFlow() &&
                                    <Paper style={{ padding: 16 }}>
                                        {this.getComdataCheckFallbackText()}
                                        <CheckDetailsFormComdataFallback
                                            onSubmit={this.sendInvoice}
                                            initialValues={this.getInitialValuesComdataFallback()}
                                            signatureEnabled={signatureEnabled}
                                            signatureUrl={this.state.signatureUrl}
                                            openAddSignatureModal={this.openAddSignatureModal}
                                        />
                                    </Paper>
                                }
                                {this.showComdataFleetCardFallbackFlow() &&
                                    <Paper style={{ padding: 16 }}>
                                        <P variant="h4" gutterBottom>We're working on it</P>
                                        <br />
                                        <P gutterBottom>Fuel cards currently need to be registered to a check number for processing.</P>
                                        <br />
                                        <P gutterBottom>Please have the following Comchek Number registered to the Express Code so we can continue processing this payment.</P>
                                        <br />
                                        <P align="center" variant="h3" gutterBottom>{deposit?.checkNumber}</P>
                                        <br />
                                        <P gutterBottom>Please provide some additional information.</P>
                                        <br />
                                        <CheckDetailsFormComdataFallback
                                            onSubmit={this.sendInvoice}
                                            initialValues={this.getInitialValuesComdataFallback()}
                                            signatureEnabled={signatureEnabled}
                                            signatureUrl={this.state.signatureUrl}
                                            openAddSignatureModal={this.openAddSignatureModal}
                                        />
                                    </Paper>
                                }
                            </Grid>
                        </Grid>
                    </InvoicePreparationStepContainer>
                </InvoiceFinalizeContainer>
                <CreditCardProcessingModal show={showProcessingModal} />
            </LoadingContainer>
        )
    }
}

type PropsFromState = Pick<GlobalState, "invoices" | "deposits" | "locations" | "appSettings" | "auth"> & {
    getFieldsToshow?: CustomField[];
    deposit?: Deposit;
    invoice?: Invoice;
    signatureEnabled?: boolean;
    isConfirmationEnabled?: boolean;
    company?: Company;
    comdataApiLocationId?: string;
    reducePaperCheckFlowEnabled?: boolean;
}

export const mapStateToProps = (
    { invoices, deposits, locations, companies, appSettings, auth }: GlobalState,
    { match: { params: { invoiceId } } }: RouteComponentProps<RouteParams>
): PropsFromState & OwnProps => {
    const invoice = invoices?.data?.[invoiceId] as Invoice;
    const company = "string" === typeof invoice?.company ? companies?.data?.[invoice.company] : undefined;
    return {
        appSettings,
        deposits,
        invoice,
        invoices,
        locations,
        getFieldsToshow: locations?.data?.[String(invoice.location)]?.customFields?.filter((item) => item.isShownInList),
        deposit: deposits?.data?.[invoiceId],
        signatureEnabled: isSignatureEnabled(company),
        isConfirmationEnabled: isConfirmationBeforeSendingEnabled(company),
        reducePaperCheckFlowEnabled: isReducePaperCheckFlowEnabled(company),
        company,
        auth,
        comdataApiLocationId: locations?.data?.[String(invoice.location)]?.comdataApiLocationId
    };
};

export default withRouter(connect(mapStateToProps)(CheckDetails));
