import React from "react";
import ToggleButton from '../../components/app/ToggleButton';
import { forceValueToString, toFixed } from "../../services/app/formats";
import { Grid } from "@material-ui/core";
import { getMIUIGridScale, ProductGridType } from "../../types/UserInterface";
import { ProductSelectorProps } from "./ProductSelector";

interface State {
  currentQty?: string;
}

class ProductTypeCustomSelector extends React.Component<ProductSelectorProps, State> {

  constructor(props: ProductSelectorProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.preventClickPropagation = this.preventClickPropagation.bind(this);
    this.state = {};
  }

  componentDidMount(): void {
    this.resetCurrentQty();
  }

  componentDidUpdate(prevProps: Readonly<ProductSelectorProps>, prevState: Readonly<State>, snapshot?: any): void {
    const { product, qty } = this.props;
    if (prevProps.qty !== this.props.qty) {
      this.setState({ currentQty: toFixed(forceValueToString(qty || product.cost)) });
    }
  }

  resetCurrentQty(): void {
    const { product, qty } = this.props;
    this.setState({
      currentQty: toFixed(forceValueToString(qty || product.cost))
    });
  }

  handleClick(): void {
    const { onToggle, product } = this.props;
    this.resetCurrentQty();
    onToggle(product.id);
  }

  preventClickPropagation(e: React.MouseEvent<HTMLInputElement>): void {
    e.stopPropagation();
  }

  handleValueChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ currentQty: e.currentTarget.value?.replace(/[^0-9.]+/gi, "") });
  }

  handleBlur(): void {
    const { onChange, product } = this.props;
    const currentQty = toFixed(this.state.currentQty);
    this.setState({ currentQty });
    onChange(product.id, currentQty);
  }

  handleFirstFocus(event: React.ChangeEvent<HTMLInputElement>): void {
    event.target.select();
    event.target.onfocus = null;
  };

  // eslint-disable-next-line
  render(): React.ReactElement {
    const { product, selected, gridType } = this.props;
    const { currentQty } = this.state;
    const itemClass = "pos-item pos-item-v2";
    const isListView = gridType === ProductGridType.List;
    const isSmallGridView = gridType === ProductGridType.SmallGrid;
    return (
      <Grid item {...getMIUIGridScale(gridType)} className={`${itemClass} ${selected ? 'is-active' : ''}`}>
        <ToggleButton isActive={selected} onClick={this.handleClick}>
          <div className={`newPos-wrapper newPos-wrapper-v2 ${isListView ? "newPos-wrapper__list" : ""}`}>
            <span className={`title-wrap title-wrap-v2 ${isListView ? "title-wrap__list" : ""} ${isSmallGridView ? "title-wrap__small-grid" : ""}`}>
              {product?.name}
            </span>
            <span className={`price-wrap price-wrap-v2 ${isListView ? "price-wrap__list" : ""}`}>
              {product?.cost &&
                <span className={`price price-v2 ${isListView ? "price__list" : ""}`}>
                  ${currentQty}
                </span>
              }
              {selected &&
                <span className="price-inp-wrap price-inp-wrap-amount amount_wrap price-inp-wrap-amount--custom-amount">
                  <input
                    type="text"
                    id="customProductInput"
                    onClick={this.preventClickPropagation}
                    onChange={this.handleValueChange}
                    onFocus={this.handleFirstFocus}
                    data-testid="price"
                    onBlur={this.handleBlur}
                    value={currentQty}
                  />
                </span>
              }
            </span>
          </div>
        </ToggleButton>
      </Grid>
    );
  }
}

export default ProductTypeCustomSelector;
