import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { sendInvoiceForCompletion } from '../../../../actions/invoices';
import { InvoicePaths } from '../../../../services/app/paths';
import { normalizePhoneOrEmail } from '../../../../services/app/normalizers';
import { WithInvoiceProps } from '../../../../components/ui/WithInvoiceProps';
import { InvoicePreparationStepProps } from '../../../../types/InvoicePreparationStepProps';
import { Invoice } from '../../../../types/Invoice';
import InvoiceFinalizeContainer from '../../../../components/invoice/InvoiceFinalizeContainer';
import { Error } from '../../../../components/ui/Error';
import { GlobalState } from '../../../../types/GlobalState';
import { isEqual } from 'lodash';
import DirectPaymentCheckoutForm, { DirectPaymentCheckoutFormData } from '../../../../components/invoice/DirectPaymentCheckoutForm';
import CustomStyledAlert from '../../../../components/invoice/CustomStyledAlert';
import { directPaymentMessage } from '../../../../components/product/ProductListTotal';
import { PaymentMethods } from '../../../../constants/invoice';
import styles from './DirectPaymentCheckout.css';
import { Box, StyledComponentProps, withStyles } from '@material-ui/core';
import { FullScreenLoader } from '@roadsync/roadsync-ui';
import { payButtonPressed } from '../../../../services/api/invoices';
import { Company } from '../../../../types';
import { isAdvanceToCheckoutDirectPaymentsEnabled } from '../../../../services/app/company';

interface RouteParams {
    invoiceId: string;
}

type PropsFromState = Pick<GlobalState, 'auth' | 'invoices' | 'companies'>;

interface Props extends WithInvoiceProps, InvoicePreparationStepProps, PropsFromState, DispatchProp, RouteComponentProps<RouteParams>, StyledComponentProps { }

interface State {
    error?: string;
    loading: boolean;
    isAdvanceToCheckoutDirectPaymentsOn?: boolean;
}

class DirectPaymentCheckout extends React.Component<Props, State> {

    private mounted: boolean = false;

    constructor(props: Props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { loading: true };
    }

    componentDidMount(): void {
        this.mounted = true;
        if (this.mounted) this.setState({ loading: false });
        const company = this.getCompany();
        const isAdvanceToCheckoutDirectPaymentsOn = isAdvanceToCheckoutDirectPaymentsEnabled(company);

        this.setState({ isAdvanceToCheckoutDirectPaymentsOn });
    }

    shouldComponentUpdate(nextProps, nextState): boolean {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
    }

    componentWillUnmount(): void {
        this.mounted = false;
    }

    getCompany(): Company | undefined {
        const { companies, auth: { me } } = this.props;
        return companies.data?.[me.companyId];
    }

    async handleSubmit(values: DirectPaymentCheckoutFormData): Promise<void> {
        if (this.mounted) this.setState({ loading: true });
        try {
            const { history, dispatch } = this.props;
            const invoiceId = this.getInvoiceId();
            const phone = normalizePhoneOrEmail(values.payerPhone);
            payButtonPressed(this.getInvoice());
            await dispatch<any>(sendInvoiceForCompletion(invoiceId, undefined, phone));
            history.push(InvoicePaths.listUrl());
        } catch (e) {
            if (this.mounted) this.setState({ error: (e as any)?.message });
        }
        if (this.mounted) this.setState({ loading: false });
    }

    getInvoiceId(): string {
        return this.props.match.params.invoiceId;
    }

    getInvoice(): Invoice | undefined {
        const { invoices } = this.props;
        return invoices.data?.[this.getInvoiceId()];
    }

    getInitialValues(): Partial<DirectPaymentCheckoutFormData> {
        const invoice = this.getInvoice();
        return { payerPhone: invoice?.payerPhone };
    }

    showDirectPaymentAlert(): boolean {
        return this.getInvoice()?.type === PaymentMethods.DIRECT_PAYMENT.key;
    }

    render(): React.ReactElement {
        const { onCompletedStep, classes } = this.props;
        const { loading, error, isAdvanceToCheckoutDirectPaymentsOn } = this.state;
        return <>
            <FullScreenLoader show={loading} />
            {isAdvanceToCheckoutDirectPaymentsOn && this.showDirectPaymentAlert() &&
                <Box mb={2}>
                    <CustomStyledAlert className={classes?.directPaymentAlertMobileOnly}>
                        {directPaymentMessage}
                    </CustomStyledAlert>
                </Box>
            }
            <InvoiceFinalizeContainer formName='directPaymentCheckout' onCompletedStep={onCompletedStep}>
                <Error error={error} mt={2} mb={2} />
                <DirectPaymentCheckoutForm initialValues={this.getInitialValues()} onSubmit={this.handleSubmit} />
            </InvoiceFinalizeContainer>
        </>;
    }
}

/* istanbul ignore next */
const mapStateToProps = ({ auth, invoices, companies }): PropsFromState => ({ auth, invoices, companies });
export default withRouter(connect(mapStateToProps)(withStyles(styles)(DirectPaymentCheckout)));
