import React from "react";
import ToggleButton from "../../components/app/ToggleButton";
import { displayProductQtyFieldBasedOnProductUnitValue, ProductSelectorProps } from "./ProductSelector";
import { Grid } from "@material-ui/core";
import { getMIUIGridScale, ProductGridType } from "../../types/UserInterface";
import { formatDollar } from "../../services/app/formats";
import { ProductInputLabel } from "./ProductInputLabel";
import { ProductUnit } from "../../constants/product";

interface State {
    qty?: number | string;
}
class ProductTypeItemSelector extends React.Component<ProductSelectorProps, State> {
    constructor(props: ProductSelectorProps) {
        super(props);
        this.handleToggle = this.handleToggle.bind(this);
        this.setInputValue = this.setInputValue.bind(this);

        this.state = { qty: props.qty };
    }

    componentDidUpdate(prevProps: Readonly<ProductSelectorProps>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.qty !== this.props.qty) {
            this.setState({ qty: this.props.qty });
        }
    }

    handleToggle(): void {
        const { onToggle, product } = this.props;
        onToggle(product.id);
    }

    setInputValue(value: any): void {
        const { onChange, product } = this.props;
        this.setState({ qty: value });
        onChange(product.id, value);
    }

    render(): React.ReactElement {
        const { id, product, selected, gridType } = this.props;
        const { qty } = this.state;
        const isSmallGrid = gridType === ProductGridType.SmallGrid;
        const isList = gridType === ProductGridType.List;
        const displayLabel = product.unit === ProductUnit.WHOLE.key || product.unit === ProductUnit.PARTIAL.key;

        return (
            <Grid
                key={`key-en-${id}`}
                item
                id={`feature-enabled-${id}`}
                {...getMIUIGridScale(gridType)}
                className={`pos-item pos-item-v2 ${selected ? "is-active" : ""}`}
            >
                <ToggleButton isActive={selected} onClick={this.handleToggle}>
                    <div className={`newPos-wrapper newPos-wrapper-v2 ${isList ? "newPos-wrapper__list" : ""}`}>
                        <span
                            className={`title-wrap title-wrap-v2 ${isSmallGrid ? "title-wrap__small-grid" : ""} ${isList ? "title-wrap__list" : ""}`}
                        >
                            {product.name}
                        </span>
                        <span className={`price-wrap price-wrap-v2 ${isList ? "price-wrap__list" : ""}`}>
                            <span className={`price price-v2 ${isList ? "price__list" : ""}`}>
                                {formatDollar(product.cost)} <span className="mobile-only-pos"> / Unit</span>
                            </span>
                        </span>
                        {selected && (
                            <div className="display-label-wrap">
                                {displayLabel && <ProductInputLabel>Quantity</ProductInputLabel>}
                                <span className="price-inp-wrap price-inp-wrap-amount amount_wrap">
                                    {displayProductQtyFieldBasedOnProductUnitValue(product, this.setInputValue, id, qty)}
                                </span>
                            </div>
                        )}
                    </div>
                </ToggleButton>
            </Grid>
        );
    }
}

export default ProductTypeItemSelector;

