import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ModalsConstants } from '../../../constants/modals';
import { openModal } from '../../../actions/modals';
import WorkOrderEditForm from '../../../components/workOrder/WorkOrderEditForm';
import { handleReduxFormError } from '../../../services/app/forms';
import { createWorkOrder, getWorkOrder, updateWorkOrder } from '../../../actions/workOrders';
import { WorkOrderPaths } from '../../../services/app/paths';
import { withRouter, RouteComponentProps } from 'react-router';
import { isEmail } from '../../../services/api/utils';
import { change, getFormValues } from 'redux-form';
import { get } from 'lodash';
import {
  getLocationsArrayFromSettings,
  getPreselectedLocationValuesIfOnlyOneOptionAvailable
} from '../../../services/app/location';
import { isClientSupport, isEmployee, isCompanyAdmin, isAccountant } from '../../../services/app/auth';
import { normalizePhoneOrEmail } from "../../../services/app/normalizers";
import { WorkOrder } from '../../../types/WorkOrder';
import { searchCarriersByCompanyId } from "../../../actions/carriers";
import { Carrier } from "../../../types/carrier";
import { WorkOrderTemplate } from '../../../types/WorkOrderTemplate';
import { AppSettings } from '../../../types/AppSettings';
import { Department } from '../../../types/Department';
import { Shift } from '../../../types/Shift';
import { LoadingContainer } from '../../../components/ui/visibility/LoadingContainer';
import { GlobalState } from '../../../types/GlobalState';
import AppBarContainer from '../../../components/ui/AppBarContainer';

export enum AuthorizationType {
  Driver = 'driver',
  Recipient = 'recipient',
}

interface WorkOrderType extends WorkOrder {
  loading?: boolean;
  phoneOrEmail?: string;
  signedCustomFields?: { [k: string]: string };
}

interface LocationType extends Location {
  departments: string[];
  shifts: string[];
}

interface RouteParams {
  workOrderId: string;
}

type PropsFromState = Pick<GlobalState, "auth" | "workOrders"> & OwnProps;

interface OwnProps {
  companyId: string;
  appSettings: {
    settings: AppSettings;
    defaults?: {
      departmentId: string;
      locationId: string;
      shiftId: string;
    };
  };
  departments: { data: { [k: string]: Department } };
  locations: {
    data: { [k: string]: LocationType };
    departments?: string[];
  };
  shifts: { data: { [k: string]: Shift } };
  workOrderId;
  workOrder: WorkOrderType;
  formValues: any;
}

interface Props extends PropsFromState, OwnProps, DispatchProp, RouteComponentProps<RouteParams> { }

interface State {
  carrierOptions: Carrier[];
  shiftsList: any[];
  departmentsList: any[];
  edit?: boolean;
  workOrderTemplate?: WorkOrderTemplate;
  defaultWorkOrderTemplate?: string;
}

class CreateWorkOrder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.handleNewSubmit = this.handleNewSubmit.bind(this);
    this.handleUpdateSubmit = this.handleUpdateSubmit.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.onWorkOrderTemplateChange = this.onWorkOrderTemplateChange.bind(this);
    this.loadCarriers = this.loadCarriers.bind(this);
    this.setDefaultWorkOrderTemplate = this.setDefaultWorkOrderTemplate.bind(this);
    this.state = {
      carrierOptions: [],
      shiftsList: [],
      departmentsList: [],
      edit: false,
      workOrderTemplate: undefined,
      defaultWorkOrderTemplate: undefined,
    };
  }



  loadCarriers(q): any {
    const { dispatch, auth: { me } } = this.props;
  
    return dispatch<any>(searchCarriersByCompanyId(me.companyId, q))
      .then(data => {
        this.setState({ carrierOptions: data.map(item => item.carrierName) });
      });
  }

  componentDidMount(): any {
    const { dispatch, workOrders, match: { params: { workOrderId } } } = this.props;
    if (workOrderId) {
      this.setState({ edit: true });
      const workOrder = workOrders.data[workOrderId];
      if (!workOrder?.id) {
        dispatch<any>(getWorkOrder(workOrderId));
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<Props & RouteComponentProps>, prevState: Readonly<State>): void {
    if (prevState.defaultWorkOrderTemplate !== this.state.defaultWorkOrderTemplate) {
      this.props.dispatch(change('editWorkOrder', 'workOrderSettingsId', this.state.defaultWorkOrderTemplate));
    }
  }

  onClose(): void {
    const { dispatch, history, companyId, formValues } = this.props;
    if (formValues.workOrderSettingsId && formValues.reference) {
      dispatch(openModal(ModalsConstants.HANDLE_WORK_ORDER_DISMISS, {
        values: formValues,
        companyId: companyId,
      }));
    }
    history.push(WorkOrderPaths.workOrdersUrl());
  }

  handleUpdateSubmit(values): void {
    const { dispatch, history, workOrder } = this.props;
    values.phoneOrEmail = normalizePhoneOrEmail(values.phoneOrEmail);

    const updatedWorkOrder: Partial<WorkOrder> = {
      id: workOrder.id,
      reference: values.reference,
      signedCarrierName: values.signedCarrierName,
      signedDriverName: values.signedDriverName,
      signedDriverEmail: isEmail(values.phoneOrEmail) ? values.phoneOrEmail : null,
      signedDriverPhone: isEmail(values.phoneOrEmail) ? null : values.phoneOrEmail,
      workOrderSettingsId: values.workOrderSettingsId,
      signedCustomFields: this.prepareCustomFields(values),
    };

    return dispatch<any>(updateWorkOrder(updatedWorkOrder as WorkOrder)).then(workOrderId => {
      if (values.authorization === AuthorizationType.Recipient) {
        history.push(WorkOrderPaths.workOrderSendUrl(workOrderId));
      } else if (values.authorization === AuthorizationType.Driver) {
        history.push(WorkOrderPaths.workOrderPrivateAuthorizationUrl(workOrderId));
      }
    }).catch((err) => {
      if (err && err.formSubmissionError && err.formSubmissionError.signedDriverPhone) {
        err.formSubmissionError.phoneOrEmail = err.formSubmissionError.signedDriverPhone;
      }
      return handleReduxFormError(err);
    });
  }

  prepareCustomFields(values: { [k: string]: string }): { [k: string]: string } {
    const result: { [k: string]: string } = {};
    if (this.state.workOrderTemplate?.customFields) {
      this.state.workOrderTemplate.customFields.forEach((item) => {
        result[item.name] = values[item.name];
      });
    }
    return result;
  }

  // eslint-disable-next-line max-lines-per-function
  async handleNewSubmit(values): Promise<void> {
    const { companyId, dispatch, history, appSettings } = this.props;

    values.phoneOrEmail = normalizePhoneOrEmail(values.phoneOrEmail);

    const workOrder: Partial<WorkOrder> = {
      reference: values.reference,
      workOrderSettingsId: values.workOrderSettingsId,
      signedCarrierName: values.signedCarrierName,
      signedDriverName: values.signedDriverName,
      signedDriverEmail: isEmail(values.phoneOrEmail) ? values.phoneOrEmail : null,
      signedDriverPhone: isEmail(values.phoneOrEmail) ? null : values.phoneOrEmail,
      locationId: values.locationId,
      signedCustomFields: this.prepareCustomFields(values),
    };
    // Default Work Order location for employees
    if (values.locationId == null) {
      workOrder.locationId = appSettings && appSettings.defaults && appSettings.defaults.locationId;
    }
    return dispatch<any>(createWorkOrder(workOrder as WorkOrder, companyId))
      .then(workOrderId => {
        if (values.authorization === 'recipient') {
          history.push(WorkOrderPaths.workOrderSendUrl(workOrderId));
        } else if (values.authorization === 'driver') {
          history.push(WorkOrderPaths.workOrderPrivateAuthorizationUrl(workOrderId));
        }
      })
      .catch((err) => {
        if (err && err.formSubmissionError && err.formSubmissionError.signedDriverPhone) {
          err.formSubmissionError.phoneOrEmail = err.formSubmissionError.signedDriverPhone;
        }

        return handleReduxFormError(err);
      });
  }

  getDepartmentsByLocationId(locationId: string): void {
    const { locations, departments, dispatch } = this.props;
    const location = get(locations.data, locationId);
    const departmentsList = location?.departments?.map(id => departments.data[id]);

    if (departmentsList && departmentsList.length === 1) {
      dispatch(change('editWorkOrder', 'departmentId', departmentsList[0].id));
    } else {
      dispatch(change('editWorkOrder', 'departmentId', ''));
    }
    this.setState({ departmentsList });
  }

  getShiftsByLocationId(locationId: string): void {
    const { locations, shifts, dispatch } = this.props;
    const location = get(locations.data, locationId);
    const shiftsList = location?.shifts?.map(id => shifts.data[id]);
    if (shiftsList && shiftsList.length === 1) {
      dispatch(change('editWorkOrder', 'shiftId', shiftsList[0].id));
    } else {
      dispatch(change('editWorkOrder', 'shiftId', ''));
    }
    this.setState({ shiftsList });
  }

  onLocationChange(event, newValue, previousValue): void {
    if (newValue !== previousValue) {
      this.getShiftsByLocationId(newValue);
      this.getDepartmentsByLocationId(newValue);
    }
  }

  setDefaultWorkOrderTemplate(template): void {
    this.setState({ defaultWorkOrderTemplate: template });
  }

  onWorkOrderTemplateChange(workOrderTemplate?: WorkOrderTemplate): void {
    this.setState({ workOrderTemplate });
  }

  isWorkOrderLoading(): boolean {
    const { workOrder } = this.props;
    const { edit } = this.state;
    return !!((!workOrder && edit) || (workOrder && workOrder.loading));
  }

  // eslint-disable-next-line max-lines-per-function,max-statements,complexity
  render(): React.ReactElement {
    const { workOrder, locations, departments, shifts, appSettings: { settings, defaults }, auth: { me } } = this.props;
    const { edit } = this.state;
    const title = !edit ? 'New Work Order Authorization' : 'Edit Work Order Authorization';

    let WOAData = workOrder;

    const { departmentsList, shiftsList } = this.state;
    const locationData = getLocationsArrayFromSettings(settings, locations as any, me.companyId);

    if (WOAData) {
      WOAData.phoneOrEmail = (WOAData.signedDriverEmail ? WOAData.signedDriverEmail : WOAData.signedDriverPhone);
      if (WOAData.signedCustomFields) {
        Object.keys(WOAData.signedCustomFields).forEach((id) => {
          WOAData[id] = WOAData?.signedCustomFields?.[id];
        });
      }
    } else {
      let preselectedValues;
      // eslint-disable-next-line no-mixed-operators
      if (isCompanyAdmin(me) || isAccountant(me) || isEmployee(me) && isClientSupport(me)) {
        preselectedValues = getPreselectedLocationValuesIfOnlyOneOptionAvailable(
          settings, locations as any, shifts, departments, me.companyId,
        );
      } else {
        preselectedValues = {
          locationId: defaults?.locationId,
          departmentId: defaults?.departmentId,
          shiftId: defaults?.shiftId,
        }
      }

      WOAData = preselectedValues;
    }

    return (
      <LoadingContainer loading={this.isWorkOrderLoading()}>
        <AppBarContainer onExit={this.onClose} title="Work Order">
          <WorkOrderEditForm
            // eslint-disable-next-line
            // @ts-ignore
            initialValues={WOAData}
            carriersList={this.state.carrierOptions}
            loadCarriers={this.loadCarriers}
            locations={isEmployee(me) && isClientSupport(me)
              ? locationData?.filter(location => settings ? settings?.user?.locationIds.indexOf(location.id) >= 0 : location)
              : locationData}
            departments={departmentsList}
            shifts={shiftsList}
            // eslint-disable-next-line no-mixed-operators
            showLocationPicker={!(defaults || WOAData && edit)}
            // eslint-disable-next-line no-mixed-operators
            showDepartmentPicker={!(defaults || WOAData && edit)}
            // eslint-disable-next-line no-mixed-operators
            showShiftPicker={!(defaults || WOAData && edit)}
            onLocationChange={this.onLocationChange}
            onWorkOrderTemplateChange={this.onWorkOrderTemplateChange}
            onSubmit={!edit ? this.handleNewSubmit : this.handleUpdateSubmit}
            setDefaultWorkOrderTemplate={this.setDefaultWorkOrderTemplate}
            title={title}
          />
        </AppBarContainer>
      </LoadingContainer>
    );
  }
}

const mapStateToProps = (state: GlobalState & Props, props: Props): PropsFromState => {
  const {
    locations, departments, shifts, appSettings,
    auth, workOrders } = state;

  return {
    auth,
    locations,
    departments,
    shifts: shifts,
    appSettings: appSettings,
    formValues: getFormValues('editWorkOrder')(state) || {},
    workOrderId: props?.match?.params?.workOrderId,
    workOrders,
    workOrder: workOrders.data[props?.match?.params?.workOrderId],
    companyId: auth.me?.companyId,
  };
}

const component = connect(mapStateToProps)(CreateWorkOrder);
export default withRouter(component);
