import React from "react";

type InputNumberProps = {
  id?: string
  value?: number;
  className?: string;
  placeholder?: string;
  setInputValue: (value?: string | number) => void;
}

// eslint-disable-next-line
const InputNumber: React.FC<InputNumberProps> = (props: InputNumberProps) => {
  const { id, value, className, placeholder, setInputValue } = props;

  function updateValueManually(event: React.ChangeEvent<HTMLInputElement>): void {
    const wholeNumberRex = /^[0-9\b]+$/;
    if (event.target.value === '' || wholeNumberRex.test(event.target.value)) {
      setInputValue(Math.max(1, Number(event.target.value)));
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
    event.stopPropagation();
  }

  const increment = (e: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
    e.stopPropagation();
    if (value) {
      setInputValue(value + 1);
    }
  }

  function decrement(e: React.MouseEvent<HTMLInputElement, MouseEvent>): void {
    e.stopPropagation();
    if (value && value > 1) {
      setInputValue(Math.max(1, value - 1));
    }
  }

  function handleFirstFocus(event: React.ChangeEvent<HTMLInputElement>): void {
    event.target.select();
    event.target.onfocus = null;
  };

  return (
    <span className={className}>
      <span id={`decrement-button-${id}`} data-testid={`decrement-button-${id}`} onClick={decrement} className="btn-amount btn-minus icon-minus btn_minus"></span>
      <input
        id={id}
        key={id}
        data-testid={`quantity-${id}`}
        type="text" // type number would allow to input a period even if you don't want it and the value does not include it
        placeholder={placeholder}
        value={value}
        onClick={handleClick}
        onFocus={handleFirstFocus}
        onChange={updateValueManually}
        min="1"
        step="1"
      />
      <span id={`increment-button-${id}`} data-testid={`increment-button-${id}`} onClick={increment} className="btn-amount btn-plus icon-plus btn_plus"></span>
    </span>
  )
}

export default InputNumber;
