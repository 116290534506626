import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { depositVGSCard } from '../../../../actions/deposits';
import { InvoicePaths } from '../../../../services/app/paths';
import { Invoice, Company, GlobalState, InvoicePreparationStepProps } from '../../../../types';
import { isSignatureEnabled, isLicenseRequiredEnabled, isConfirmationBeforeSendingEnabled } from '../../../../services/app/company';
import { openModal } from '../../../../actions/modals';
import { ModalsConstants } from '../../../../constants/modals';
import InvoiceFinalizeContainer from '../../../../components/invoice/InvoiceFinalizeContainer';
import CardDetailsForm, { CardDetailsFormData } from './CardDetailsForm';
import { P, FullScreenLoader, Grid } from '@roadsync/roadsync-ui';
import { Error as ErrorComponent } from '../../../../components/ui/Error';
import { getInvoice, payButtonPressed } from '../../../../services/api/invoices';
import { InvoiceDeclineReasons } from '../../../../constants/invoiceDeclineReasons';
import { AuthState } from '../../../../reducers/auth';
import { CompaniesState } from '../../../../reducers/companies';
import { PublicDataState } from '../../../../reducers/public';
import VGSCreditCardForm, { createVgsCreditCardForm } from '../../../../components/vgsCreditCardForm/VgsCreditCardForm';
import { VGSForm } from '../../../../services';
import { VgsFormValues } from '../../../../types/VgsForm';
import { getVgsToken } from '../../../../services/api/wallet';
import { isEqual } from 'lodash';

type PropsFromState = Pick<GlobalState, "appSettings" | "invoices"> & {
    isConfirmationEnabled?: boolean;
    licenseRequired?: boolean;
    invoice: Invoice;
    companyId: string;
    signatureEnabled?: boolean;
    auth: AuthState;
    companies: CompaniesState;
    publicData: PublicDataState;
}

interface RouteParams {
    invoiceId: string;
}

interface CardVGSDepositParams {
    invoiceId: string,
    invoiceToken: string,
    license: string,
    billingFirstName: string,
    billingLastName: string,
    streetAddress: string,
    city: string,
    state: string,
    zipCode: string,
    paymentToken: string,
    cvv: string,
    ccexp: string
}

interface Props extends InvoicePreparationStepProps, PropsFromState, RouteComponentProps<RouteParams>, DispatchProp { }

interface State {
    signatureUrl?: string;
    error?: string;
    submitting?: boolean;
    isSignatureRequired?: boolean;
    vgsForm?: VGSForm;
    vgsFormValues?: VgsFormValues;
    vgsFormValid?: boolean;
}

class CardDetails extends React.Component<Props, State> {
    private mounted: boolean = false;
    static ccNumberFieldSelector = "#ccnumber";
    static ccExpFieldSelector = "#ccexp";
    static cvvFieldSelector = "#cvv";

    constructor(props: Props) {
        super(props);
        this.openAddSignatureModal = this.openAddSignatureModal.bind(this);
        this.signatureUploaded = this.signatureUploaded.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getDeclineReason = this.getDeclineReason.bind(this);
        this.depositVGSCard = this.depositVGSCard.bind(this);
        this.getVGSError = this.getVGSError.bind(this);
        this.onVgsFormChange = this.onVgsFormChange.bind(this);
        this.state = { submitting: false };
    }

    async componentDidMount(): Promise<void> {
        this.mounted = true;
        this.initVgsForm();
    }

    shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
    }

    componentWillUnmount(): void {
        this.mounted = false;
    }

    getDeclineReason(): string | undefined {
        const { invoice } = this.props;
        return invoice?.paymentError
            ? InvoiceDeclineReasons.getByKey(invoice.paymentError)?.display || `Error: ${invoice.paymentError}`
            : undefined;
    }

    setError(error = "Unable to process your request"): void {
        if (!this.mounted) return;
        const declineReason = InvoiceDeclineReasons.getByKey(error)?.display;
        this.setState({ error: declineReason ?? error, submitting: false });
    }

    onVgsFormChange(values: VgsFormValues) {
        if (!this.mounted) return;
        this.setState({
            vgsFormValues: values,
            vgsFormValid: values?.card_number?.isValid && values?.card_exp?.isValid && values?.card_cvc?.isValid,
        });
    }

    async initVgsForm(): Promise<void> {
        const { appSettings } = this.props;
        if (!this.mounted) return;
        try {
            const vgsForm = await createVgsCreditCardForm(
                appSettings.settings?.config?.veryGoodSecurityVaultId,
                appSettings.settings?.config?.veryGoodSecurityEnvironment,
                this.onVgsFormChange,
            );
            this.setState({ vgsForm });
        } catch (e) {
            this.setError((e as any)?.message);
        }
    }

    async depositVGSCard(params: CardVGSDepositParams): Promise<void> {
        if (!this.mounted) return;
        try {
            const { dispatch, history } = this.props;
            await dispatch<any>(depositVGSCard(
                this.getInvoiceId(),
                params.invoiceToken,
                params.license,
                params.billingFirstName,
                params.billingLastName,
                params.streetAddress,
                params.city,
                params.state,
                params.zipCode,
                params.paymentToken,
                params.cvv,
                params.ccexp
            )
            );
            await this.checkForPaymentErrors();
            history.push(InvoicePaths.listUrl());
        } catch (e) {
            this.setError((e as any)?.message);
        } finally {
            this.setState({ submitting: false });
        }
    }

    async checkForPaymentErrors(): Promise<void> {
        const { dispatch } = this.props;
        const invoice = dispatch(await getInvoice(this.getInvoiceId()));
        if (invoice?.paymentError) {
            throw new Error(invoice?.paymentError);
        }
    }

    validate(): void {
        const err = this.getSignatureError() || this.getVGSError();
        if (err) {
            throw new Error(err);
        }
    }

    getVGSError(): string | undefined {
        const { vgsFormValid, vgsFormValues } = this.state;

        if (vgsFormValid) {
            return undefined;
        }

        if (!!vgsFormValues?.card_number?.errorMessages?.length) {
            return 'Card Number is not valid. Please update and try again.';
        }

        if (!!vgsFormValues?.card_cvc?.errorMessages?.length) {
            return 'CVC is not valid. Please update and try again.';
        }

        if (!!vgsFormValues?.card_exp?.errorMessages?.length) {
            return 'Expiration date is not valid. Please update and try again.';
        }

        return 'Please verify the card details';
    }

    async handleSubmit(event: MouseEvent, values?: CardDetailsFormData): Promise<void> {
        const { invoice } = this.props;
        const { vgsForm } = this.state;
        if (!vgsForm) return;
        if (!this.mounted) return;
        try {
            event.preventDefault();
            this.setState({ error: undefined, submitting: true });
            this.validate();
            const token = await getVgsToken(vgsForm);

            payButtonPressed(invoice);

            await this.depositVGSCard({
                invoiceId: this.getInvoiceId(),
                invoiceToken: invoice.token ?? "",
                license: values?.license ?? "",
                billingFirstName: values?.billingFirstName ?? "",
                billingLastName: values?.billingLastName ?? "",
                streetAddress: values?.streetAddress ?? "",
                state: values?.cardState ?? "",
                city: values?.city ?? "",
                zipCode: values?.zipCode ?? "",
                paymentToken: token.card_number,
                cvv: token.card_cvc,
                ccexp: token.card_exp.replaceAll(/\D/g, ''),
            });
        } catch (e) {
            this.setError((e as any).message);
        } finally {
            this.setState({ submitting: false });
        }
    }

    getSignatureError(): string | undefined {
        const { signatureUrl, isSignatureRequired } = this.state;
        return isSignatureRequired && !signatureUrl ? "Please add your signature to continue" : undefined;
    }

    getInvoiceId(): string {
        const { match: { params: { invoiceId } } } = this.props;
        return invoiceId;
    }

    signatureUploaded(signatureUrl: string): void {
        if (!this.mounted) return;
        this.setState({ signatureUrl, error: undefined });
    }

    openAddSignatureModal(): void {
        const { dispatch } = this.props;
        return dispatch<any>(openModal(ModalsConstants.SIGNATURE, {
            invoiceId: this.getInvoiceId(),
            signatureUploaded: this.signatureUploaded,
        }));
    }

    render(): React.ReactNode {
        const { onCompletedStep, licenseRequired, invoice } = this.props;
        const { signatureUrl, error, submitting, isSignatureRequired } = this.state;

        return (
            <InvoiceFinalizeContainer onCompletedStep={onCompletedStep} formName="cardDetails" handleSubmit={this.handleSubmit}>
                <P variant="h4" gutterBottom>
                    Please fill out the payment details below.
                </P>
                <ErrorComponent error={error} />
                <FullScreenLoader show={submitting} />
                <Grid container spacing={2}>
                    <Grid item><VGSCreditCardForm /></Grid>
                </Grid>
                <CardDetailsForm
                    invoice={invoice}
                    openAddSignatureModal={this.openAddSignatureModal}
                    licenseRequired={licenseRequired}
                    signatureEnabled={isSignatureRequired}
                    signatureUrl={signatureUrl}
                >
                </CardDetailsForm>
            </InvoiceFinalizeContainer>
        );
    }
}

export const mapStateToProps = (
    { appSettings, invoices, companies, auth, publicData }: GlobalState,
    { match: { params: { invoiceId } } }: RouteComponentProps<RouteParams>
): PropsFromState => {
    const invoice = invoices?.data?.[invoiceId] as Invoice;
    const company = companies?.data?.[String(invoice.company)] as Company;
    const companyId = company?.id as string;
    return {
        appSettings,
        invoices,
        invoice,
        isConfirmationEnabled: isConfirmationBeforeSendingEnabled(company),
        signatureEnabled: isSignatureEnabled(company),
        licenseRequired: isLicenseRequiredEnabled(company),
        companyId,
        auth,
        companies,
        publicData,
    };
};

export default withRouter(connect(mapStateToProps)(CardDetails));
