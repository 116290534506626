import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, InjectedFormProps, formValueSelector } from 'redux-form';
import { email, emailOrPhoneNumber, required, usPhoneNumber } from '../../services/app/validators';
import { Error } from '../ui/Error';
import { RenderTextField } from '../ui/Form';
import { Grid } from '@roadsync/roadsync-ui';
import { LargePrimaryButton, LargeButton } from '../ui/Buttons';
import { Radio, FormControlLabel, Box } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RenderRadioGroup } from '../ui/form/RenderRadioGroup';
import InvoicePrepButtonRow from './fullScreenStepper/InvoicePrepButtonRow';
import { Location } from '../../types/Location';
import { Department } from '../../types/Department';
import { Shift } from '../../types/Shift';
import { Carrier } from '../../types/carrier';
import { LineItem } from '../../types/LineItems';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import styles from './PayerInfoForm.css';
import { getCarrierByCompanyId } from '../../services/api/carriers';

const form = 'payerInfo';

export interface PayerInfoFormData {
  payerName?: string;
  payerPhone?: string;
  payerEmail?: string;
  locationId?: string;
  departmentId?: string;
  shiftId?: string;
  lineItems?: LineItem[];
  description?: string | null;
  comments?: string | null;
}

interface OwnProps {
  companyId: string;
  showLocationOptions?: boolean;
  departments?: Department[];
  locations?: Location[];
  shifts?: Shift[];
  payerPhoneOrEmailFieldName: 'payerPhone' | 'payerEmail';
  onPrevStep?: () => void;
  onLocationChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isAdvanceToCheckoutDirectPaymentsOn?: boolean;
  payerPhone?: string;
  payerEmail?: string;
}

type InjectedProps = InjectedFormProps<PayerInfoFormData, OwnProps>;
type Props = OwnProps & InjectedProps & StyledComponentProps;

// eslint-disable-next-line max-lines-per-function
const PayerInfoForm: React.FC<Props> = (props: Props): React.ReactElement => {

  const {
    error, handleSubmit, invalid, submitting, locations, showLocationOptions, departments,
    shifts, onLocationChange, payerPhoneOrEmailFieldName, initialValues, onPrevStep,
    isAdvanceToCheckoutDirectPaymentsOn, payerPhone, payerEmail, classes, companyId,
  } = props;

  const [carrierSearchText, setCarrierSearchText] = useState('');
  const [carrerList, setCarrierOptions] = useState<string[]>([]);
  const [carrierSearchIsOpen, setCarrierSearchIsOpen] = useState(false);
  const [isCarrierSearchLoading, setIsCarrierSearchLoading] = useState(false);
  const minSearchLength = 1;

  useEffect(() => {
    if (carrierSearchText.length >= minSearchLength) {
      setIsCarrierSearchLoading(true);
      getCarrierByCompanyId(companyId, carrierSearchText)
        .then((r: Carrier[]) => setCarrierOptions(r.map((c) => c.carrierName)))
        .finally(() => setIsCarrierSearchLoading(false));
    }
  }, [carrierSearchText, companyId]);

  useEffect(() => {
    if (!carrierSearchIsOpen) setCarrierOptions([]);
  }, [carrierSearchIsOpen]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction='column' spacing={4}>
        {error && <Grid item><Error error={error} /></Grid>}
        <Grid item>
          <Autocomplete
            id='carrier-list'
            freeSolo
            options={carrerList}
            defaultValue={initialValues?.payerName || ''}
            onOpen={() => setCarrierSearchIsOpen(true)}
            onClose={() => setCarrierSearchIsOpen(false)}
            loading={isCarrierSearchLoading}
            renderInput={(params): JSX.Element => (
              <Field
                name='payerName'
                label={"Customer Name"}
                component={RenderTextField}
                onChange={(e: React.ChangeEvent) => setCarrierSearchText((e.target as HTMLInputElement).value ?? '')}
                validate={required}
                required
                {...params}
              />
            )}
          />
        </Grid>

        {!isAdvanceToCheckoutDirectPaymentsOn &&
          <Grid item>
            <Field
              id='payer-phone-or-email'
              name={payerPhoneOrEmailFieldName}
              label={"Payer Phone or Email"}
              type='text'
              helperText={"This information will be used to send the payer the invoice and receipt"}
              component={RenderTextField}
              validate={[required, emailOrPhoneNumber]}
              required
            />
          </Grid>
        }

        {isAdvanceToCheckoutDirectPaymentsOn &&
          <>
            <Grid item>
              <Field
                id='payer-phone'
                name='payerPhone'
                label="Driver's Phone"
                type='text'
                component={RenderTextField}
                validate={!!payerEmail ? undefined : [required, usPhoneNumber]}
                required={!payerEmail}
                disabled={!!payerEmail}
              />
              <Box className={classes?.hint}>If the driver is paying by RoadSync Direct Payment, the driver's phone number is required.</Box>
            </Grid>
            <Grid item>
              <Field
                id='payer-email'
                name='payerEmail'
                label="Driver's Email"
                type='text'
                component={RenderTextField}
                validate={!!payerPhone ? undefined : [required, email]}
                required={!payerPhone}
                disabled={!!payerPhone}
              />
            </Grid>
          </>
        }
        {showLocationOptions && !!locations?.length &&
          <Grid item>
            <Field
              id='location-id'
              name='locationId'
              label='Select a Location'
              validate={required}
              required
              component={RenderRadioGroup}
              onChange={onLocationChange}
              onBlur={onLocationChange}
            >
              {locations.map((location) => (
                <FormControlLabel
                  key={location.id}
                  value={location.id}
                  control={<Radio color='primary' data-public />}
                  label={location.name} />
              ))}
            </Field>
          </Grid>
        }

        {showLocationOptions && !!departments?.length &&
          <Grid item>
            <Field
              id='department-id'
              name='departmentId'
              label='Department'
              validate={required}
              required
              component={RenderRadioGroup}
            >
              {departments.map((d) => (
                <FormControlLabel
                  key={d.id}
                  value={d.id}
                  control={<Radio color='primary' data-public />}
                  label={d.name} />
              ))}
            </Field>
          </Grid>
        }

        {showLocationOptions && !!shifts?.length &&
          <Grid item>
            <Field
              id='shift-id'
              name='shiftId'
              label='Shift'
              type='text'
              validate={required}
              required
              component={RenderRadioGroup}
            >
              {shifts.map((s) => (
                <FormControlLabel
                  key={s.id}
                  value={s.id}
                  control={<Radio color='primary' data-public />}
                  label={s.name}
                />
              ))}
            </Field>
          </Grid>
        }

        <Grid item>
          <InvoicePrepButtonRow
            secondaryBtn={
              <LargeButton variant='outlined' onClick={onPrevStep} id='payer-info-form-cancel'>
                Cancel
              </LargeButton>
            }>
            <LargePrimaryButton disabled={submitting || invalid} type='submit' id='payer-info-form-submit'>
              Save &amp; Continue
            </LargePrimaryButton>
          </InvoicePrepButtonRow>
        </Grid>
      </Grid>
    </form>
  );
};

const selector = formValueSelector(form);
export default connect(
  state => {
    const { payerPhone, payerEmail } = selector(state, 'payerPhone', 'payerEmail');
    return {
      payerPhone,
      payerEmail
    }
  }
)(withStyles(styles)(reduxForm<PayerInfoFormData, OwnProps>({ form, enableReinitialize: true })(PayerInfoForm)));
