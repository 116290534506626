import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import _ from 'lodash';

type InputCustomProps = {
  type?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  step?: number | string;
  errorMessage?: string;
  required?: boolean;
  ComponentWhenActive?: JSX.Element;
}

type Props = InputCustomProps & WrappedFieldProps;

// eslint-disable-next-line max-lines-per-function
const Input: React.FC<Props> = (props: Props) => {
  const {
    input, meta: { touched, error, active }, disabled, label, step, type, className,
    inputClassName, placeholder, errorMessage, required, ComponentWhenActive,
    ...rest
  } = props;
  const backendError = errorMessage?.includes(_.lowerCase(input.name));

  return (
    <div
      className={`inp-row
        ${className || ''}
        ${(touched && error) || (backendError ? 'field-validation-error' : '')}
        ${required ? 'input-required' : ''}`}
    >
      {label && <label>{label}</label>}
      <div>
        <div className={`input-wrapper ${(touched && !error) ? ' success-data' : ''}`}>
          <input
            step={step}
            disabled={disabled}
            className={`${inputClassName || ''}`}
            aria-label={label}
            {...input}
            placeholder={placeholder || label}
            type={type}
            {...rest}
          />
        </div>
        {/* eslint-disable-next-line no-mixed-operators */}
        {(touched && error || backendError) && <span className="field-error-message">{error}</span>}
        {active && ComponentWhenActive && <>{ComponentWhenActive}</>}
      </div>
    </div>
  );
};

export default Input;
