/* eslint-disable max-lines */
import React from "react";
import { connect, DispatchProp } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { getFormValues } from "redux-form";
import { InvoicePaths } from "../../../../services/app/paths";
import {
    getPreparedInvoice,
    getDetailsStepByInvoiceType,
    calculateInvoiceTotal,
    getPriceBreakdown,
    sortProductsByWeight,
    isRemoteCheckout,
} from "../../../../services/app/invoice";
import { getInvoice, updateInvoice } from "../../../../actions/invoices";
import { getProducts } from "../../../../actions/products";
import ProductSelectorList from "../../../product/management/ProductSelectorList";
import ProductListTotal, { directPaymentMessage, directPaymentFeatureOffMessage } from "../../../../components/product/ProductListTotal";
import { InvoiceStatuses, PaymentMethods, PreparationSteps } from "../../../../constants/invoice";
import { Company } from "../../../../types/Company";
import {
    isAdvanceToCheckoutDirectPaymentsEnabled,
    isAttachInvoicePdfEnabled,
    isMultipleLineItemsEnabled,
    isProductsCommentEnabled,
    isRainforestPayEnabled,
} from "../../../../services/app/company";
import { Deposit } from "../../../../types/Deposit";
import { ProductSelectType, ProductType } from "../../../../constants/product";
import CategorySelectForm, { FormData as CategorySelectFormData } from "../../../../components/invoice/CategorySelectForm";
import { Category } from "../../../../types/Category";
import LineItemsCommentsSection from "./LineItemsCommentsSection";
import { Invoice } from "../../../../types/Invoice";
import { LineItem } from "../../../../types/LineItems";
import { InvoiceEndpoints } from "../../../../services/api/endpoints";
import { Error } from "../../../../components/ui/Error";
import { Product } from "../../../../types/Product";
import { CompanySettings } from "../../../../types/CompanySettings";
import { Fee } from "../../../../types/fee";
import InvoiceTotalThresholdAlert from "../InvoiceTotalThresholdAlert";
import { Box, Grid, Hidden, StyledComponentProps, Tab, Tabs, withStyles } from "@material-ui/core";
import TabPanel from "../../../../components/ui/tabs/TabPanel";
import { InvoiceTab } from "../../../../types/InvoiceTab";
import AttachFile from "./AttachFile";
import InvoiceAddMultipleLineItemsForm, { CustomLineItem } from "./InvoiceAddMultipleLineItemsForm";
import { PriceBreakdown } from "../../../../types/PriceBreakdown";
import { GlobalState } from "../../../../types/GlobalState";
import { InvoiceCommentsFormData } from "../../../../components/invoice/InvoiceCommentsForm";
import { showErrorAlert } from "../../../../actions/alerts";
import { InvoiceTabLabel } from "../../../../components/invoice/InvoiceTabLabel";
import { FieldError } from "../../../../services/api/utils";
import styles from "./LineItems.css";
import { OnCompletedStepProps } from "../../../../types/OnCompletedStepProps";
import { ProductGridType } from "../../../../types/UserInterface";
import { LargeButton } from "../../../../components/ui/Buttons";
import gridLayout from "../../../../assets/img/svg/gridLayout.svg";
import listLayout from "../../../../assets/img/svg/listLayout.svg";
import { isBoolean, isEqual } from "lodash";
import { FullScreenLoader } from "@roadsync/roadsync-ui";
import { depositClonedCard } from "../../../../actions/deposits";
import ConfirmCardOnFileModal from "./cardOnFile/ConfirmCardOnFileModal";
import CustomStyledAlert from "../../../../components/invoice/CustomStyledAlert";
import { isAchPaymentEnabled } from "../../../../services/app/company";
import { App } from "../../../../constants/app";
import { CreditCardProcessingModal } from "./CreditCardProcessingModal";
import { debounce } from "lodash";
interface State {
    selectedProducts?: LineItem[];
    error?: string;
    invoiceThresholdAcceptanceRequired?: boolean;
    comments?: string | null;
    invoiceThresholdAcceptanceVerified?: boolean;
    selectedTab: number;
    price?: PriceBreakdown;
    selectedGridLayout: ProductGridType;
    storePaymentDetails?: boolean;
    isAchPaymentOn?: boolean;
    shouldEnableAchForInvoice?: boolean;
    isCardOnFileClonedInvoice?: boolean;
    isConfirmCardOnFileModalOpen: boolean;
    loading?: boolean;
    isAdvanceToCheckoutDirectPaymentsOn?: boolean;
    loadProductSelectorList: boolean;
    invoice?: Invoice;
    isTowbookInvoice?: boolean;
    isRainforestMcrKillSwitchEnabled: boolean;
}

interface OwnProps extends OnCompletedStepProps, RouteComponentProps<RouteParams> {
    company: Company;
    previewInvoice?: boolean;
    towbookInvoiceId?: string;
}

interface RouteParams {
    invoiceId: string;
}

type PropsFromState = Pick<GlobalState, "auth" | "companies" | "companySettings" | "publicData" | "deposits" | "invoices" | "products"> & {
    companyId: string;
    formValues: CategorySelectFormData;
    fees?: Fee[];
    invoiceId: string;
};

interface Props extends PropsFromState, RouteComponentProps<RouteParams>, DispatchProp, OwnProps, OnCompletedStepProps, StyledComponentProps {}

class LineItems extends React.Component<Props, State> {
    private mounted = false;
    defaultErrMsg = "Something went wrong. Please try again";
    // eslint-disable-next-line max-lines-per-function,max-statements
    constructor(props: Props) {
        super(props);
        this.handleProductSelection = this.handleProductSelection.bind(this);
        this.handleProductUpdate = this.handleProductUpdate.bind(this);
        this.handleCommentsUpdate = this.handleCommentsUpdate.bind(this);
        this.updateConvFee = this.updateConvFee.bind(this);
        this.verifyInvoiceAmountThreshold = this.verifyInvoiceAmountThreshold.bind(this);
        this.verifyCompanyInvoiceAmounts = this.verifyCompanyInvoiceAmounts.bind(this);
        this.changePaymentMethod = this.changePaymentMethod.bind(this);
        this.handlePrintReceipt = this.handlePrintReceipt.bind(this);
        this.saveCustomLineItems = this.saveCustomLineItems.bind(this);
        this.updateTotals = this.updateTotals.bind(this);
        this.useGridView = this.useGridView.bind(this);
        this.useListView = this.useListView.bind(this);
        this.setStorePaymentDetails = this.setStorePaymentDetails.bind(this);
        this.setShouldEnableAchForInvoice = this.setShouldEnableAchForInvoice.bind(this);
        this.setLoadProductSelectorList = this.setLoadProductSelectorList.bind(this);
        this.setIsCardOnFileClonedInvoice = this.setIsCardOnFileClonedInvoice.bind(this);
        this.handleConfirmCardOnFileModalOpen = this.handleConfirmCardOnFileModalOpen.bind(this);
        this.handleConfirmCardOnFileModalClose = this.handleConfirmCardOnFileModalClose.bind(this);
        this.handlePayForCardOnFileClonedInvoice = this.handlePayForCardOnFileClonedInvoice.bind(this);
        this.updateSelectedProducts = this.updateSelectedProducts.bind(this);
        this.syncInvoiceAndProductSelectorData = this.syncInvoiceAndProductSelectorData.bind(this);
        this.state = {
            selectedTab: 0,
            comments: "",
            selectedGridLayout: ProductGridType.MediumGrid,
            isConfirmCardOnFileModalOpen: false,
            loadProductSelectorList: false,
            invoice: undefined,
            isRainforestMcrKillSwitchEnabled: true,
            shouldEnableAchForInvoice: false,
        };
    }

    get isProcessing(): boolean {
        return this.getInvoice().status === InvoiceStatuses.PROCESSING.key;
    }

    async componentDidMount(): Promise<void> {
        this.mounted = true;
        this.setState({ loading: true });
        const { dispatch, history } = this.props;
        const isRainforestMcrKillSwitchEnabled = !isRainforestPayEnabled(this.getCompany());
        const invoice = this.getInvoice();
        this.setState({
            isTowbookInvoice: invoice.source === "towbook",
            isRainforestMcrKillSwitchEnabled,
        });

        if (invoice.status === InvoiceStatuses.COMPLETED.key) {
            history.push(InvoicePaths.listUrl());
        }

        try {
            // This call is relatively cheap, so it can't hurt to load the latest data
            // about the invoice from the API on load, regardless of how we got here.
            await dispatch<any>(getInvoice(this.getInvoiceId()));
            await dispatch<any>(getProducts(this.getLocationId(), 0, App.GET_ALL));

            // put to state the latest invoice
            this.setState({ invoice: invoice });

            this.savePreselectedProducts();

            const company = this.getCompany();
            const isAdvanceToCheckoutDirectPaymentsOn = isAdvanceToCheckoutDirectPaymentsEnabled(company);
            const isAchPaymentOn = isAchPaymentEnabled(company);

            this.setState({ isAdvanceToCheckoutDirectPaymentsOn, isAchPaymentOn, shouldEnableAchForInvoice: invoice?.paymentSettings?.isAchEnabled });

            this.setIsCardOnFileClonedInvoice();
            if (this.mounted)
                this.setState({
                    comments: this.getInvoice()?.comments,
                    storePaymentDetails: this.getInvoice()?.storePaymentDetails,
                });

            // loadProductSelectorList is set to true so that the ProductSelectorList component only loads
            // after we get the most updated invoice and all of its attributes including comments
            // and this is used to fix an issue with comments not being saved properly
            this.setLoadProductSelectorList(true);
            await this.updateTotals();
        } catch (e) {
            dispatch(showErrorAlert((e as any)?.message || "Something went wrong."));
        }

        this.setState({ loading: false });
    }

    componentWillUnmount(): void {
        this.mounted = false;
    }

    shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    async syncInvoiceAndProductSelectorData(invoice?: Invoice): Promise<void> {
        await this.updateTotals();
        this.setState({ invoice });
        let selectedProducts = [...(invoice?.lineItems || [])];
        await this.updateSelectedProducts(selectedProducts);
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): Promise<void> {
        const { dispatch } = this.props;
        const oldInvoice = prevProps.invoices.data?.[this.getInvoiceId()];
        const newInvoice = this.props.invoices.data?.[this.getInvoiceId()];
        const oldLineItems = oldInvoice?.lineItems;
        const newLineItems = newInvoice?.lineItems;

        if (!isEqual(oldLineItems, newLineItems)) {
            try {
                await this.syncInvoiceAndProductSelectorData(newInvoice);
            } catch (e) {
                dispatch(showErrorAlert((e as any)?.message || "Something went wrong."));
            }
        }

        if (oldInvoice?.grandTotal !== newInvoice?.grandTotal) {
            try {
                await this.syncInvoiceAndProductSelectorData(newInvoice);
            } catch (e) {
                dispatch(showErrorAlert((e as any)?.message || "Something went wrong."));
            }
        }
    }

    getInvoice(): Invoice {
        const { invoices } = this.props;
        return invoices.data?.[this.getInvoiceId()] || invoices.ui?.progress || ({} as Invoice);
    }

    getPreselectedTaxes(): Partial<LineItem>[] {
        const { products } = this.props;
        return Object.keys(products.data)
            .map((id) => products.data[id])
            .filter((product) => product.type === ProductType.TAX.key)
            .filter((product) => product.selectType === ProductSelectType.PRESELECTED.key)
            .filter((product) => product.locationId === this.getLocationId())
            .map((product) => ({ product, qty: product?.cost }));
    }

    getPreselectedServices(): Partial<LineItem>[] {
        const { products } = this.props;
        return Object.keys(products.data)
            .map((id) => products.data[id])
            .filter((product) => product.type === ProductType.SERVICE.key)
            .filter((product) => product.selectType === ProductSelectType.PRESELECTED.key)
            .filter((product) => product.locationId === this.getLocationId())
            .map((product) => ({ product, qty: 1 }));
    }

    shouldSavePreselectedTaxes(): boolean {
        return this.getPreselectedTaxes().length > 0 && this.getInvoice().lineItems.length < 1;
    }

    shouldSavePreselectedServices(): boolean {
        return this.getPreselectedServices().length > 0 && this.getInvoice().lineItems.length < 1;
    }

    savePreselectedProducts(): void {
        const { invoices } = this.props;
        const { invoice } = this.state;
        const invoiceLineItems = invoice?.lineItems || invoices.ui?.progress?.lineItems || [];
        let selectedProducts = [...invoiceLineItems];
        const shouldSavePreselectedTaxes = this.shouldSavePreselectedTaxes();
        const shouldSavePreselectedServices = this.shouldSavePreselectedServices();

        // Fixes RSC-1688 Preselected taxes get deselected if you go straight to Line Items tab, add new item and use "Taxable" toggle.
        // Putting it into this.state would cause a new bug with calculate totals, so I keep to this solution
        if (shouldSavePreselectedTaxes) selectedProducts = [...selectedProducts, ...(this.getPreselectedTaxes() as LineItem[])];

        if (shouldSavePreselectedServices) selectedProducts = [...selectedProducts, ...(this.getPreselectedServices() as LineItem[])];

        if (shouldSavePreselectedTaxes || shouldSavePreselectedServices) this.handleProductUpdate(selectedProducts);
    }

    setIsCardOnFileClonedInvoice(): void {
        const invoice = this.getInvoice();
        if (this.mounted)
            this.setState({
                isCardOnFileClonedInvoice:
                    !!invoice?.storedPaymentDetails?.id &&
                    !invoice.deleteStoredPaymentDetails &&
                    !!invoice?.storedPaymentDetails?.maskedCardNumber &&
                    !!invoice?.storedPaymentDetails?.cardType,
            });
    }

    getSelectedProducts(): LineItem[] {
        const { selectedProducts } = this.state;
        return selectedProducts || this.getInvoice()?.lineItems || [];
    }

    async updateTotals(invoice: Invoice = this.getInvoice(), lineItems: LineItem[] = this.getSelectedProducts()): Promise<PriceBreakdown> {
        const { fees } = this.props;
        const { isTowbookInvoice } = this.state;

        if (isTowbookInvoice) {
            const towbookLineItem: LineItem[] = lineItems.filter((lineItem) => lineItem.type === ProductType.ADD_MULTIPLE_LINE_ITEMS.key);
            const price = await calculateInvoiceTotal({ ...invoice, lineItems: towbookLineItem }, fees);
            if (this.mounted) this.setState({ price });
            return price;
        }

        const price = await calculateInvoiceTotal({ ...invoice, lineItems }, fees);
        if (this.mounted) this.setState({ price });
        return price;
    }

    getCompany(): Company | undefined {
        const invoice = this.getInvoice();
        const { companies } = this.props;
        return "string" === typeof invoice.company ? companies?.data?.[invoice.company] : invoice.company;
    }

    getInvoiceWithCurrentComments(): Invoice {
        const { comments } = this.state;
        const invoice = this.state.invoice || ({} as Invoice);
        return { ...invoice, comments: comments || invoice?.comments };
    }

    isCustomLineItemsProduct(): boolean {
        const { products } = this.props;
        const id = this.getCustomLineItemsProductId();
        return !!id && !!products?.data?.[id];
    }

    getLocationId(): string {
        const invoice = this.getInvoice();
        return invoice.locationId ? invoice.locationId : "string" === typeof invoice.location ? invoice.location : invoice.location?.id;
    }

    getAvailableProducts(): { [id: string]: Product } {
        const { products } = this.props;
        const result: { [id: string]: Product } = {};
        Object.keys(products.data)
            .map((id) => products.data[id])
            .filter((product) => product.type !== ProductType.CATEGORY.key)
            .filter((product) => product.locationId === this.getLocationId())
            .filter((product) => product.id || product.productId)
            .forEach((product) => {
                result[product.id || product.productId] = product;
            });
        return result;
    }

    getCustomLineItems(): CustomLineItem[] {
        return this.getSelectedProducts()
            .filter((lineItem) => lineItem.product.type === ProductType.ADD_MULTIPLE_LINE_ITEMS.key)
            .map(
                (lineItem: LineItem): CustomLineItem => ({
                    cost: Number(lineItem.qty) || 0, // qty is real "cost" for lineItems
                    isTaxable: lineItem.isTaxable,
                    description: lineItem.description ?? "",
                })
            );
    }

    getSelectedProductsExceptCustomLineItems(): LineItem[] {
        const productId = this.getCustomLineItemsProductId() as string;
        return this.getSelectedProducts()
            .filter((i) => i.type !== ProductType.ADD_MULTIPLE_LINE_ITEMS.key)
            .filter((i) => i.productId !== productId && i.product?.id !== productId);
    }

    saveCustomLineItems(customLineItems?: CustomLineItem[]): void {
        const currentLineItems = this.getSelectedProductsExceptCustomLineItems();
        const productId = this.getCustomLineItemsProductId() as string;
        const type = ProductType.ADD_MULTIPLE_LINE_ITEMS.key;
        const lineItemsFromCustom: LineItem[] = (customLineItems || []).map((i) => {
            const base = { ...i, productId, qty: i.cost, type };
            return { ...base, id: "", product: { ...base, id: productId } };
        });
        const selectedProducts = [...currentLineItems, ...lineItemsFromCustom];
        this.handleProductUpdate(selectedProducts);
    }

    setStorePaymentDetails(storePaymentDetails?: boolean): void {
        if (this.mounted) this.setState({ storePaymentDetails });
    }

    async setShouldEnableAchForInvoice(shouldEnableAchForInvoice?: boolean): Promise<void> {
        if (this.mounted) this.setState({ shouldEnableAchForInvoice });
        const { dispatch } = this.props;
        const paymentSettings = { isAchEnabled: shouldEnableAchForInvoice };
        const invoice = { ...this.getInvoice(), paymentSettings };
        const invoiceToSave = getPreparedInvoice(invoice, this.getSelectedProducts());
        await dispatch<any>(updateInvoice(invoiceToSave));
    }

    setLoadProductSelectorList(loadProductSelectorList: boolean): void {
        if (this.mounted) this.setState({ loadProductSelectorList });
    }

    handleProductUpdate(selectedProducts: LineItem[] = []): void {
        const lineItems = selectedProducts.map((item) => {
            item.productId = item.productId || item?.product?.id || item?.product?.productId || undefined;
            return item;
        });
        this.handleProductSelection(lineItems, false, false, false);
    }

    updateConvFee(isEnabled?: boolean): void {
        this.handleConvFeeUpdate(isEnabled);
    }

    debouncedSaveComments = debounce((comments: string) => {
        this.setState({ comments }, () => {
            this.saveInvoice();
        });
    }, 300);

    handleCommentsUpdate(values: InvoiceCommentsFormData): void {
        const invoice = this.getInvoice();
        const comments = values?.comments || "";
        invoice.comments = comments;
        if (this.mounted) {
            this.debouncedSaveComments(comments);
        }
    }

    async handleConvFeeUpdate(isEnabled?: boolean): Promise<void> {
        const { dispatch } = this.props;
        const invoice = { ...this.getInvoice(), convFeeDisable: !isEnabled };
        const invoiceToSave = getPreparedInvoice(invoice, this.getSelectedProducts());
        await dispatch<any>(updateInvoice(invoiceToSave));
        this.updateTotals();
    }

    // fixes RSC-1477, RSC-1995 bug. On initial create invoice with card on file feature on the invoice.storePaymentDetails value used to be set undefined by the ProductSelectorList container
    // before the toggle loaded and the state value was set
    getStorePaymentDetailsValueToSave(): boolean | undefined {
        const { storePaymentDetails } = this.state;
        const shouldUseStorePaymentDetailsStateValue = isBoolean(storePaymentDetails);
        return shouldUseStorePaymentDetailsStateValue ? storePaymentDetails : this.getInvoice()?.storePaymentDetails;
    }

    async saveInvoice(): Promise<Invoice> {
        console.log("saveInvoice");
        const { dispatch } = this.props;
        const { comments, shouldEnableAchForInvoice } = this.state;
        const storePaymentDetails = this.getStorePaymentDetailsValueToSave();
        const paymentSettings = { isAchEnabled: shouldEnableAchForInvoice };
        const invoice = { ...this.getInvoice(), comments, storePaymentDetails, paymentSettings };
        const invoiceToSave = getPreparedInvoice(invoice, this.getSelectedProducts());
        return dispatch<any>(updateInvoice(invoiceToSave));
    }

    async saveClonedCardOnFileInvoice(deleteStoredPaymentDetails: boolean = false): Promise<Invoice> {
        const { dispatch } = this.props;
        const { comments, storePaymentDetails } = this.state;
        const invoice = { ...this.getInvoice(), comments, storePaymentDetails, deleteStoredPaymentDetails };
        const invoiceToSave = getPreparedInvoice(invoice, this.getSelectedProducts());
        return dispatch<any>(updateInvoice(invoiceToSave));
    }

    isPrintButtonEnabled(): boolean {
        const invoice = this.getInvoice();
        return invoice.type !== PaymentMethods.REMOTE_CHECKOUT.key;
    }

    changePaymentMethod(): void {
        this.updateInvoice(PreparationSteps.PAYMENT_METHOD);
    }

    verifyInvoiceAmountThreshold(): void {
        if (this.mounted) this.setState({ invoiceThresholdAcceptanceVerified: true, error: "" });
    }

    verifyCompanyInvoiceAmounts(): void {
        const { price, invoiceThresholdAcceptanceVerified } = this.state;
        const grandTotal = parseFloat((price?.GrandTotal || 0).toString());
        const settings = this.getCompanySettings();
        const maxAmount = parseFloat((settings?.invoiceLineItemAmountMaximum || "0").toString());
        const thresholdAmount = parseFloat((settings?.invoiceLineItemAmountThreshold || "0").toString());
        const isOverThreshold = !isNaN(thresholdAmount) && thresholdAmount > 0 && grandTotal > thresholdAmount;
        const isOverMax = !isNaN(maxAmount) && maxAmount > 0 && grandTotal > maxAmount;
        if (this.mounted)
            this.setState({
                invoiceThresholdAcceptanceRequired: isOverThreshold || isOverMax,
                invoiceThresholdAcceptanceVerified: invoiceThresholdAcceptanceVerified,
            });
    }

    async updateInvoice(nextStep?: PreparationSteps): Promise<Invoice> {
        const { onCompletedStep } = this.props;
        const invoice = await this.saveInvoice();
        if (this.mounted) this.setState({ price: getPriceBreakdown(this.getInvoice()) });
        if (nextStep) {
            onCompletedStep(nextStep);
        }
        return invoice; // it is being used in handleProductSelection -> this.updateInvoice(nextStep) -> then -> resolve(invoice);
    }

    async handlePrintReceipt(): Promise<void> {
        await this.handleProductSelection(this.getSelectedProducts(), true, undefined, false);
        this.openPrintInvoiceWindow();
    }

    getCustomLineItemsProductId(): string | undefined {
        const { products } = this.props;
        return Object.keys(products?.data || [])
            .filter((id) => products.data[id].type === ProductType.ADD_MULTIPLE_LINE_ITEMS.key)
            .shift();
    }

    getCompanySettings(): CompanySettings | undefined {
        const { companyId, companySettings } = this.props;
        return companySettings.data[companyId];
    }

    updateSelectedProducts(selectedProducts: LineItem[]): Promise<void> {
        return new Promise((resolve) => {
            this.setState({ selectedProducts }, resolve);
        });
    }

    // eslint-disable-next-line max-params, max-lines-per-function
    async handleProductSelection(
        selectedProducts: LineItem[],
        exitNow?: boolean,
        sendDetails?: boolean,
        redirectOnSuccess = true
    ): Promise<Invoice | void> {
        const { history, dispatch } = this.props;
        const invoice = this.getInvoice();
        const nextStep = redirectOnSuccess
            ? sendDetails
                ? PreparationSteps.SEND_INVOICE_DETAILS_TO_PAYER
                : getDetailsStepByInvoiceType(this.getInvoice())
            : undefined;

        this.setState({ loading: true });

        try {
            await this.updateSelectedProducts(selectedProducts);
            await this.updateInvoice(nextStep);
            this.verifyCompanyInvoiceAmounts();
            if (exitNow) {
                history.push(InvoicePaths.listUrl());
            }
        } catch (e) {
            const err = e as any;
            if (err.formSubmissionError.fieldErrors) {
                const typedErrors = (err.formSubmissionError.fieldErrors ?? []) as FieldError[];
                const errorMessage = typedErrors
                    .map((value) => {
                        const capitalizedFieldName = value.FieldName[0].toUpperCase() + value.FieldName.substring(1);
                        return `Field "${capitalizedFieldName}" : ${value.Message}`;
                    })
                    .join(", ")
                    .trim();
                if (invoice?.status !== InvoiceStatuses.PROCESSING.key) {
                    dispatch(showErrorAlert(`Error saving invoice. ${errorMessage || err?.message || ""}`));
                }
            } else {
                if (invoice?.status !== InvoiceStatuses.PROCESSING.key) {
                    dispatch(showErrorAlert(`Error saving invoice: ${err?.message || "please try again"}`));
                }
            }
        } finally {
            this.setState({ loading: false });
        }
    }

    getCategoryList(): Category[] {
        const { products } = this.props;
        const invoice = this.getInvoice();
        const categoryListAllLocs: Category[] = [
            {
                id: "-1",
                locationId: (invoice.locationId || invoice.location) as string,
                name: "All Categories",
                type: "category",
            },
        ];

        Object.keys(products.data).forEach((key) => {
            if (products.data[key].type === ProductType.CATEGORY.key) {
                categoryListAllLocs.push(products.data[key] as any);
            }
        });
        return categoryListAllLocs.filter((category) => category.locationId === (invoice.locationId || invoice.location));
    }

    showMultipleLineItemsTab(): boolean {
        return isMultipleLineItemsEnabled(this.getCompany()) && this.isCustomLineItemsProduct();
    }

    showCommentsTab(): boolean {
        return isProductsCommentEnabled(this.getCompany());
    }

    showAttachmentsTab(): boolean {
        return isAttachInvoicePdfEnabled(this.getCompany());
    }

    getTabHeaders(): React.ReactNode {
        const { selectedTab, isTowbookInvoice } = this.state;

        return (
            <Tabs
                value={selectedTab}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                onChange={(e, newTab): void => {
                    if (this.mounted) this.setState({ selectedTab: newTab });
                }}
            >
                {!isTowbookInvoice && this.getTab(InvoiceTab.Products, "products-tab-btn")}
                {!isTowbookInvoice && this.showMultipleLineItemsTab() && this.getTab(InvoiceTab.LineItems, "line-items-tab-btn")}
                {this.showCommentsTab() && this.getTab(InvoiceTab.Comments, "comments-tab-btn")}
                {this.showAttachmentsTab() && this.getTab(InvoiceTab.Attachments, "attachments-tab-btn")}
            </Tabs>
        );
    }

    getTab(sectionName: InvoiceTab, btnId: string): React.ReactNode {
        return <Tab label={<InvoiceTabLabel tab={sectionName} />} id={btnId} aria-controls={`tabpanel-${this.getTabIndex(sectionName)}`} />;
    }

    getTabIndex(sectionName: InvoiceTab): number {
        const { isTowbookInvoice } = this.state;
        switch (sectionName) {
            case InvoiceTab.Products:
                if (isTowbookInvoice) {
                    return -1;
                }
                return 0;
            case InvoiceTab.LineItems:
                if (isTowbookInvoice) {
                    return -1;
                }
                return 1;
            case InvoiceTab.Comments:
                if (isTowbookInvoice) {
                    return 0;
                }
                return this.showMultipleLineItemsTab() ? 2 : 1;
            case InvoiceTab.Attachments:
                if (isTowbookInvoice) {
                    return 1;
                }
                return this.showCommentsTab() && this.showMultipleLineItemsTab()
                    ? 3
                    : this.showCommentsTab() || this.showMultipleLineItemsTab()
                    ? 2
                    : 1;
            default:
                return -1;
        }
    }

    getCustomFields(): { name: string; value?: string }[] {
        const initialValues = this.getInvoiceWithCurrentComments();
        return Object.keys(initialValues?.customFields || {})
            .filter((name) => initialValues?.customFields?.[name])
            .map((name) => ({ name, value: initialValues?.customFields?.[name] }));
    }

    getProductsOrder(): string[] {
        const { products } = this.props;
        return sortProductsByWeight(Object.keys(products.data).map((id) => products.data[id])).map((p) => p.id);
    }

    useGridView(): void {
        this.selectLayout(ProductGridType.MediumGrid);
    }

    useListView(): void {
        this.selectLayout(ProductGridType.List);
    }

    // eslint-disable-next-line max-lines-per-function,max-params
    getTabContent(): React.ReactNode {
        const { formValues } = this.props;
        const { selectedTab, selectedGridLayout, isTowbookInvoice } = this.state;
        const categoryList = this.getCategoryList();
        const invoice = this.getInvoice();
        const products = this.getAvailableProducts();
        const productsOrder = this.getProductsOrder();
        const selectedProductsProps = this.getSelectedProducts();
        const invoiceWithCurrentComments = this.getInvoiceWithCurrentComments();

        return (
            <>
                <TabPanel value={selectedTab} index={0}>
                    <Grid container direction="column" spacing={1} wrap="nowrap">
                        <Grid item>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm>
                                    {categoryList?.length > 1 && !isTowbookInvoice && (
                                        <CategorySelectForm categoryList={categoryList} initialValues={{ parentId: categoryList?.[0]?.id }} />
                                    )}
                                </Grid>
                                {!isTowbookInvoice && (
                                    <>
                                        <Grid item xs={6} sm="auto">
                                            <LargeButton
                                                variant="outlined"
                                                className={this.getButtonClass()}
                                                onClick={this.useGridView}
                                                startIcon={<img src={gridLayout} alt="appStore" />}
                                            >
                                                Grid <Hidden smDown>view</Hidden>
                                            </LargeButton>
                                        </Grid>
                                        <Grid item xs={6} sm="auto">
                                            <LargeButton
                                                variant="outlined"
                                                className={this.getButtonClass()}
                                                onClick={this.useListView}
                                                startIcon={<img src={listLayout} alt="appStore" />}
                                            >
                                                List <Hidden smDown>view</Hidden>
                                            </LargeButton>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid item>
                                {!isTowbookInvoice && this.state.loadProductSelectorList && (
                                    <ProductSelectorList
                                        key="product-selector-list"
                                        invoice={invoice}
                                        products={products}
                                        productsOrder={productsOrder}
                                        parentId={formValues?.parentId || ""}
                                        onUpdate={this.handleProductUpdate}
                                        selectedProductsProps={selectedProductsProps}
                                        selectedLayout={selectedGridLayout}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </TabPanel>
                {!isTowbookInvoice && this.showMultipleLineItemsTab() && (
                    <TabPanel value={selectedTab} index={this.getTabIndex(InvoiceTab.LineItems)}>
                        <InvoiceAddMultipleLineItemsForm onSave={this.saveCustomLineItems} initialValues={{ lineItems: this.getCustomLineItems() }} />
                    </TabPanel>
                )}
                {this.showCommentsTab() && (
                    <TabPanel value={selectedTab} index={this.getTabIndex(InvoiceTab.Comments)}>
                        <LineItemsCommentsSection onChange={this.handleCommentsUpdate} initialValues={invoiceWithCurrentComments} />
                    </TabPanel>
                )}
                {this.showAttachmentsTab() && (
                    <TabPanel value={selectedTab} index={this.getTabIndex(InvoiceTab.Attachments)}>
                        <AttachFile />
                    </TabPanel>
                )}
            </>
        );
    }

    getPrintInvoiceURL(): string {
        const invoice = this.getInvoice();
        return isRemoteCheckout(invoice)
            ? InvoiceEndpoints.printInvoiceReceiptPublic(invoice.token ?? "")
            : InvoiceEndpoints.printInvoiceReceipt(invoice.id);
    }

    openPrintInvoiceWindow(): void {
        window.open(this.getPrintInvoiceURL(), "_blank");
    }

    getPaymentType(): string {
        return PaymentMethods.getByKey(this.getInvoice().type)?.key;
    }

    getDeposit(): Deposit {
        return this.props.deposits.data[this.getInvoiceId()];
    }

    getInvoiceId(): string {
        const { towbookInvoiceId } = this.props;
        const invoiceId = towbookInvoiceId ? towbookInvoiceId : this.props.match.params.invoiceId;
        return invoiceId;
    }

    selectLayout(value: ProductGridType): void {
        if (this.mounted) this.setState({ selectedGridLayout: value });
    }

    getButtonClass(): string | undefined {
        const { classes } = this.props;
        const { selectedGridLayout } = this.state;
        return selectedGridLayout ? classes?.layoutButtonActive : undefined;
    }

    handleConfirmCardOnFileModalOpen(): void {
        if (this.mounted) this.setState({ isConfirmCardOnFileModalOpen: true });
    }

    handleConfirmCardOnFileModalClose(): void {
        if (this.mounted) this.setState({ isConfirmCardOnFileModalOpen: false });
    }

    async handlePayForCardOnFileClonedInvoice(deleteStoredPaymentDetails: boolean): Promise<void> {
        const { dispatch, history } = this.props;
        if (this.mounted) {
            this.setState({ loading: true });
            try {
                await this.saveClonedCardOnFileInvoice(deleteStoredPaymentDetails);
                const responseInvoice = await dispatch<any>(depositClonedCard(this.getInvoiceId()));
                if (!responseInvoice?.paymentError) {
                    history.push(InvoicePaths.listUrl());
                    return;
                }
            } catch (e) {
                dispatch(showErrorAlert((e as any)?.message));
            }
            this.setState({ loading: false });
        }
    }

    showDirectPaymentAlert(): boolean {
        return this.getInvoice()?.type === PaymentMethods.DIRECT_PAYMENT.key;
    }

    render(): React.ReactElement {
        const { fees, classes } = this.props;
        const {
            error,
            price,
            loading,
            isCardOnFileClonedInvoice,
            isConfirmCardOnFileModalOpen,
            shouldEnableAchForInvoice,
            isAdvanceToCheckoutDirectPaymentsOn,
        } = this.state;
        const invoice = this.getInvoiceWithCurrentComments();
        const company = this.getCompany();
        const { invoiceThresholdAcceptanceRequired, invoiceThresholdAcceptanceVerified, isRainforestMcrKillSwitchEnabled } = this.state;
        const deposit = this.getDeposit();
        const selectedProducts = this.getSelectedProducts();
        return (
            <Box className={classes?.root}>
                <Grid container spacing={2} className={classes?.container}>
                    <CreditCardProcessingModal show={this.isProcessing} id="processing-modal" />
                    <FullScreenLoader show={loading} />
                    <ConfirmCardOnFileModal
                        text={`Is this the final invoice for this service?`}
                        isOpen={isConfirmCardOnFileModalOpen}
                        handleClose={this.handleConfirmCardOnFileModalClose}
                        handlePayForInvoice={this.handlePayForCardOnFileClonedInvoice}
                    />
                    <Grid item lg={7} md={7} sm={12} xs={12}>
                        {isAdvanceToCheckoutDirectPaymentsOn && this.showDirectPaymentAlert() && (
                            <CustomStyledAlert className={classes?.directPaymentAlertMobileOnly}>{directPaymentMessage}</CustomStyledAlert>
                        )}
                        {!isAdvanceToCheckoutDirectPaymentsOn && this.showDirectPaymentAlert() && (
                            <CustomStyledAlert className={classes?.directPaymentAlertMobileOnly} color="error">
                                {directPaymentFeatureOffMessage}
                            </CustomStyledAlert>
                        )}
                        {invoiceThresholdAcceptanceRequired && !invoiceThresholdAcceptanceVerified && (
                            <InvoiceTotalThresholdAlert confirm={this.verifyInvoiceAmountThreshold} />
                        )}
                        <Error error={error} />
                        {this.getTabHeaders()}
                        {this.getTabContent()}
                    </Grid>
                    <Grid item lg={5} md={5} sm={12} xs={12} className={classes?.controlsColumn}>
                        <ProductListTotal
                            isCardOnFileClonedInvoice={isCardOnFileClonedInvoice}
                            selectedProducts={selectedProducts}
                            invoice={invoice}
                            handleLineItemsSubmit={isCardOnFileClonedInvoice ? undefined : this.setStorePaymentDetails}
                            handleCardOnFileSubmit={isCardOnFileClonedInvoice ? this.handleConfirmCardOnFileModalOpen : undefined}
                            onDone={this.handleProductSelection}
                            updateConvFee={this.updateConvFee}
                            company={company}
                            handlePrintReceipt={this.handlePrintReceipt}
                            deposit={deposit}
                            changePaymentMethod={this.changePaymentMethod}
                            fees={fees}
                            price={price}
                            checkoutBtnLabel="Confirm & Checkout"
                            disableSubmitButton={!!invoiceThresholdAcceptanceRequired && !invoiceThresholdAcceptanceVerified}
                            showUseCardReader={!isRainforestMcrKillSwitchEnabled && invoice.type === PaymentMethods.CARD.key}
                            setShouldEnableAchForInvoice={this.setShouldEnableAchForInvoice}
                            shouldEnableAchForInvoice={shouldEnableAchForInvoice}
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

function mapStateToProps(state: GlobalState, ownProps: OwnProps): PropsFromState {
    const { auth, invoices, products, deposits, appSettings, companies, companySettings, publicData } = state;
    const {
        towbookInvoiceId,
        match: {
            params: { invoiceId },
        },
    } = ownProps;

    return {
        auth,
        companyId: auth.me.companyId,
        companySettings,
        companies,
        deposits,
        fees: appSettings?.settings?.publicFees,
        formValues: getFormValues("categorySelect")(state) || {},
        invoices,
        products,
        invoiceId: towbookInvoiceId ? towbookInvoiceId : invoiceId,
        publicData: publicData,
    };
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(LineItems)));
