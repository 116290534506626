import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ComdataFuelCardChargeRequest, ComdataFuelCardChargeResponse, Company, Invoice, PreEditRequest, PreEditResponse, PreEditResponseParsed } from '../../../../types';
import { GlobalState } from '../../../../types';
import { InvoicePreparationStepProps } from '../../../../types';
import InvoiceFinalizeContainer from '../../../../components/invoice/InvoiceFinalizeContainer';
import InvoicePreparationStepContainer from './InvoicePreparationStepContainer';
import { P, Grid, LoadingContainer, FullScreenLoader } from '@roadsync/roadsync-ui';
import { Error as ErrorText } from '../../../../components/ui/Error';
import { chargeComdataFuelCard, preEdit } from '../../../../services/api/deposits';
import PreEditForm, { preEditFormName } from '../../../../components/invoice/PreEditForm';
import { fuelCardComdataValidateChargeBtn, fuelCardNumber, isPreEditOrComdataFuelCardFormValid } from "../../../../services/app/validators";
import ComdataFuelCardForm, { comdataFuelCardFormName } from "../../../../components/invoice/ComdataFuelCardForm";
import { InvoicePaths } from "../../../../services/app/paths/InvoicePaths";
import { comdataPreEditParser } from "../../../../services/app/comdata/preEditParser";
import { openModal } from '../../../../actions/modals';
import { ModalsConstants } from '../../../../constants/modals';
import { isSignatureEnabled } from '../../../../services/app/company';
import VGSCardForm, { VgsCardFormState } from '../../../../components/vgsCardForm/VgsCardForm';
import { isEqual } from 'lodash';
import { comdataChargeFormValidationErrors, preEditFormValidationErrors } from '../../../../types/ComdataFuelCardErrors';
import { Moment } from 'moment';
import { InvoiceDeclineReasons } from '../../../../constants/invoiceDeclineReasons';
import { VGSForm } from '../../../../services';
import { payButtonPressed } from '../../../../services/api/invoices';

const validatePreEditSubmitBtn = (values: Record<string, any>, isVgsDisabled: boolean = true): boolean => {
    if ((isVgsDisabled && (!values.token || !!fuelCardNumber(values.token) || !values.unitNumber))
        || (!isVgsDisabled && !values.unitNumber)
    ) {
        return false;
    }
    return true;
}

interface State {
    error?: string | null;
    preEditResponseParsed?: PreEditResponseParsed | null;
    loading?: boolean;
    pristine: boolean;
    preEditValidationErrors: preEditFormValidationErrors;
    comdataChargeValidationErrors: comdataChargeFormValidationErrors;
    token?: string,
    unitNumber?: string,
    cardExpiration?: string,
    driverNumber?: string,
    hubReading?: string,
    tripNumber?: string,
    trailerHours?: string,
    trailerHubReading?: string,
    trailerNumber?: string,
    driversLicenseNumber?: string,
    driversLicenseState?: string,
    poNumber?: string,
    preEditCompleted?: boolean,
    signatureUrl?: string;
    tokenizedValue?: string;
    maskedCard?: string;
    vgsForm?: VGSForm;
    isVgsFormValid?: boolean;
}

interface RouteParams {
    invoiceId: string;
}

type PropsFromState = Pick<GlobalState, "invoices" | "companies" | "auth" | "publicData"> & {
    signatureEnabled?: boolean;
    isConfirmationEnabled?: boolean;
};

interface Props extends InvoicePreparationStepProps, RouteComponentProps<RouteParams>, DispatchProp, PropsFromState { }

class NewFuelCardDetails extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.getDeclineReason = this.getDeclineReason.bind(this);
        this.handleSubmitPreEdit = this.handleSubmitPreEdit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setDirty = this.setDirty.bind(this);
        this.setPristine = this.setPristine.bind(this);
        this.signatureUploaded = this.signatureUploaded.bind(this);
        this.setPreEditValidationErrors = this.setPreEditValidationErrors.bind(this);
        this.setComdataValidationErrors = this.setComdataValidationErrors.bind(this);
        this.openAddSignatureModal = this.openAddSignatureModal.bind(this);
        this.handleVgsFormChanged = this.handleVgsFormChanged.bind(this);
        this.handleVgsFormCreated = this.handleVgsFormCreated.bind(this);
        this.state = { pristine: true, preEditCompleted: false, preEditValidationErrors: {}, comdataChargeValidationErrors: {} };
    }

    shouldComponentUpdate(nextProps, nextState): boolean {
        return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps);
    }

    isSignatureEnabled(): boolean {
        return isSignatureEnabled(this.getCompany());
    }

    getCompany(): Company | undefined {
        const { companies } = this.props;
        const invoice = this.getInvoice();
        return "string" === typeof invoice?.company
            ? companies?.data?.[invoice.company]
            : invoice?.company;
    }

    signatureUploaded(signatureUrl: string): void {
        this.setState({ signatureUrl, error: undefined });
    }

    getInvoiceId(): string {
        const { match: { params: { invoiceId } } } = this.props;
        return invoiceId;
    }

    getInvoice(): Invoice | undefined {
        const { invoices } = this.props;
        return invoices?.data?.[this.getInvoiceId()];
    }

    showError(error = 'Something went wrong. Please try again'): void {
        this.setState({ error });
    }

    getDeclineReason(error: string): void {
        const { preEditResponseParsed } = this.state;
        error
            ? this.setState({ error: InvoiceDeclineReasons.getByKey(error)?.display || preEditResponseParsed?.paymentError })
            : this.setState({ error: `Error: ${error}` });
    }

    setDirty(): void {
        this.setState({ pristine: false });
    }

    setPristine(): void {
        this.setState({ pristine: true });
    }

    isFormValid(): boolean {
        // checks the form for validation errors that are being set in the Form -> validate
        const { preEditCompleted } = this.state;
        return preEditCompleted ? this.isComdataChargeFormValid() : this.isPreEditFormValid();
    }

    isPreEditFormValid(): boolean {
        // checks the form for validation errors that are being set in the PreEditForm -> validate
        const { preEditValidationErrors, isVgsFormValid } = this.state;
        return !!isVgsFormValid && isPreEditOrComdataFuelCardFormValid(preEditValidationErrors);
    }

    isSignatureValid(): boolean {
        const signatureDisabled = !this.isSignatureEnabled();
        const signatureEnabledAndAdded = this.isSignatureEnabled() && this.hasSignature();
        return signatureDisabled || signatureEnabledAndAdded;
    }

    isComdataChargeFormValid(): boolean {
        // checks the form for validation errors that are being set in the ComdataFuelCardForm -> validate
        const { comdataChargeValidationErrors } = this.state;
        return isPreEditOrComdataFuelCardFormValid(comdataChargeValidationErrors) && this.isSignatureValid();
    }

    setPreEditValidationErrors(preEditValidationErrors: preEditFormValidationErrors): void {
        this.setState({ preEditValidationErrors });
    }

    setComdataValidationErrors(comdataChargeValidationErrors: comdataChargeFormValidationErrors): void {
        this.setState({ comdataChargeValidationErrors });
    }

    hasSignature(): boolean {
        return !!this.state.signatureUrl;
    }

    openAddSignatureModal(): void {
        const { dispatch } = this.props;
        dispatch(openModal(ModalsConstants.SIGNATURE, {
            invoiceId: this.getInvoiceId(),
            signatureUploaded: this.signatureUploaded,
        }));
    }

    async handleSubmitPreEdit(values: Record<string, any>): Promise<void> {
        const { maskedCard } = this.state;
        const invoice = this.getInvoice();

        if (invoice?.id && invoice?.subtype && validatePreEditSubmitBtn(values, false)) {
            this.setState({ loading: true, error: undefined });
            try {
                const convertedExpirationDate = values.cardExpiration ? (values.cardExpiration as Moment).format('MM/YY') : "";
                const tokenizedValue = await this.tokenizeCardNumber();

                let requestBody: PreEditRequest = {
                    invoiceId: invoice.id,
                    cardPaymentProcessor: invoice.subtype,
                    token: tokenizedValue,
                    cardNumberMasked: maskedCard ?? '',
                    unitNumber: values.unitNumber,
                    cardExpiration: convertedExpirationDate,
                    tokenizer: 'vgs',
                };

                const preEditResponse: PreEditResponse = await preEdit(requestBody);
                const preEditResponseParsed: PreEditResponseParsed = comdataPreEditParser(preEditResponse);

                this.setState({ preEditResponseParsed });

                if (preEditResponse?.paymentError) {
                    this.getDeclineReason(preEditResponse.paymentError);
                } else {
                    this.setState({
                        pristine: true,
                        token: values.token,
                        unitNumber: values.unitNumber,
                        cardExpiration: values.cardExpiration,
                        preEditCompleted: true
                    });
                }
            } catch (e) {
                this.showError((e as any)?.message);
                this.setState({ preEditResponseParsed: null });
            }
            this.setState({ loading: false });
        }
    }

    async handleSubmit(values: Record<string, any>): Promise<void> {
        const { history } = this.props;
        const { preEditResponseParsed, signatureUrl, maskedCard } = this.state;
        const invoice = this.getInvoice();
        const signatureEnabled = this.isSignatureEnabled();

        payButtonPressed(invoice);

        if (signatureEnabled && !this.hasSignature()) {
            this.setState({ error: 'Please add your signature to continue' });
        }

        if (invoice?.id && fuelCardComdataValidateChargeBtn(values, preEditResponseParsed, false, signatureEnabled, signatureUrl)) {
            this.setState({ loading: true, error: undefined });
            const convertedExpirationDate = values.cardExpiration ? (values.cardExpiration as Moment).format('MM/YY') : "";
            try {
                const token = await this.tokenizeCardNumber();

                let requestBody: ComdataFuelCardChargeRequest = {
                    invoiceId: invoice.id,
                    cardPaymentProcessor: invoice?.subtype,
                    token: token,
                    unitNumber: values.unitNumber,
                    cardExpiration: convertedExpirationDate,
                    driverNumber: values?.driverNumber,
                    hubReading: values?.hubReading,
                    tripNumber: values?.tripNumber,
                    trailerHours: values?.trailerHours,
                    trailerHubReading: values?.trailerHubReading,
                    trailerNumber: values?.trailerNumber,
                    driversLicenseNumber: values?.driversLicenseNumber,
                    driversLicenseState: values?.driversLicenseState,
                    poNumber: values?.poNumber,
                    payerFirstName: values?.payerFirstName,
                    payerLastName: values?.payerLastName,
                    cardNumberMasked: maskedCard,
                    tokenizer: 'vgs',
                };

                const comdataFuelCardChargeResponse: ComdataFuelCardChargeResponse = await chargeComdataFuelCard(requestBody);

                if (comdataFuelCardChargeResponse.paymentError) {
                    this.getDeclineReason(comdataFuelCardChargeResponse.paymentError);
                    this.setState({
                        token: values.token,
                        unitNumber: values.unitNumber,
                        cardExpiration: values.cardExpiration,
                        driverNumber: values?.driverNumber,
                        hubReading: values?.hubReading,
                        tripNumber: values?.tripNumber,
                        trailerHours: values?.trailerHours,
                        trailerHubReading: values?.trailerHubReading,
                        trailerNumber: values?.trailerNumber,
                        driversLicenseNumber: values?.driversLicenseNumber,
                        driversLicenseState: values?.driversLicenseState,
                        poNumber: values?.poNumber,
                    });
                } else {
                    history.push(InvoicePaths.listUrl());
                }
            } catch (e) {
                this.showError((e as any)?.message);
            }
            this.setState({ loading: false });
        }
    }

    handleVgsFormCreated(vgsForm: VGSForm): void {
        this.setState({ vgsForm });
    }

    handleVgsFormChanged(state: VgsCardFormState): void {
        var maskedCard: string | undefined;
        if (state.card_number.bin && state.card_number.last4) {
            maskedCard = state.card_number.bin + "******" + state.card_number.last4;
        }

        this.setState({
            maskedCard,
            isVgsFormValid: state.card_number.isValid,
            tokenizedValue: undefined,
        });
    }

    async tokenizeCardNumber(): Promise<string> {
        const { vgsForm, tokenizedValue } = this.state;

        return new Promise((resolve, reject) => {
            if (!vgsForm) {
                reject('Card number information is invalid. Please try again');
                return;
            }

            if (tokenizedValue) {
                resolve(tokenizedValue);
                return;
            }

            vgsForm.submit('/post', {}, (status, response) => {
                if (status === 200) {
                    const card_number = response.card_number;

                    this.setState({
                        maskedCard: '560017******' + card_number.substring(card_number.length - 4, card_number.length),
                        tokenizedValue: card_number,
                    });

                    resolve(card_number);
                    return;
                }

                reject(`Invalid ${status} response. Please try again`);
            }, reject);
        });
    }

    isSubmitBtnDisabled(): boolean {
        const { loading, pristine } = this.state;
        return loading || pristine || !this.isFormValid();
    }

    render(): React.ReactElement {
        const { onCompletedStep } = this.props;
        const { error, preEditResponseParsed, loading, pristine, token, unitNumber, cardExpiration, driverNumber, hubReading, tripNumber, trailerHours, trailerHubReading,
            trailerNumber, driversLicenseNumber, driversLicenseState, poNumber, preEditCompleted } = this.state;
        const invoice = this.getInvoice();
        return (
            <InvoiceFinalizeContainer
                formName={!token && !unitNumber ? preEditFormName : comdataFuelCardFormName}
                onCompletedStep={onCompletedStep}
                disableChargeBtnComdataV2={loading || pristine || !this.isFormValid()}
                finalForm
            >
                <FullScreenLoader show={loading} />
                <InvoicePreparationStepContainer>
                    <LoadingContainer loading={!invoice}>
                        <Grid container direction="column" spacing={2} wrap="nowrap">
                            {error && <Grid item><ErrorText error={`Decline reason: ${error}`} /></Grid>}
                            <Grid item>
                                <P variant="h4" gutterBottom>Please enter the Card Number and Unit Number.</P>
                            </Grid>
                            <Grid item>
                                <VGSCardForm
                                    onFormCreated={this.handleVgsFormCreated}
                                    onFormChanged={this.handleVgsFormChanged}
                                />
                            </Grid>

                            {!preEditCompleted &&
                                <Grid item>
                                    <PreEditForm
                                        invoice={invoice}
                                        handleSubmitPreEdit={this.handleSubmitPreEdit}
                                        setDirty={this.setDirty}
                                        setPristine={this.setPristine}
                                        setValidationErrors={this.setPreEditValidationErrors}
                                    />
                                </Grid>
                            }
                            {preEditCompleted && preEditResponseParsed &&
                                <Grid item>
                                    <ComdataFuelCardForm
                                        initialValues={{
                                            token, unitNumber, cardExpiration, driverNumber, hubReading, tripNumber, trailerHours, trailerHubReading,
                                            trailerNumber, driversLicenseNumber, driversLicenseState, poNumber
                                        }}
                                        invoice={invoice}
                                        handleSubmitCharge={this.handleSubmit}
                                        signatureEnabled={this.isSignatureEnabled()}
                                        signatureUrl={this.state.signatureUrl}
                                        openAddSignatureModal={this.openAddSignatureModal}
                                        preEditResponseParsed={preEditResponseParsed}
                                        setDirty={this.setDirty}
                                        setPristine={this.setPristine}
                                        setValidationErrors={this.setComdataValidationErrors}
                                        isCardNubmerInputRequired={false}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </LoadingContainer>
                </InvoicePreparationStepContainer>
            </InvoiceFinalizeContainer>
        );
    }
}

/* istanbul ignore next */
const mapStateToProps = ({ invoices, companies, auth, publicData }: GlobalState): PropsFromState => ({ invoices, companies, auth, publicData });
export default withRouter(connect(mapStateToProps)(NewFuelCardDetails));
