import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import ProductSelector, { shouldUpdateProductQtyDecimalValue } from '../../../components/product/ProductSelector';
import { formatMoneyOnly } from '../../../services/app/formats';
import { ProductSelectType, ProductType, ProductUnit } from '../../../constants/product';
import { openModal } from '../../../actions/modals';
import { ModalsConstants } from '../../../constants/modals';
import _ from 'lodash';
import { Invoice } from "../../../types/Invoice";
import { LineItem } from "../../../types/LineItems";
import { Grid } from '@material-ui/core';
import { ProductGridType } from '../../../types/UserInterface';
import { round } from '../../../services/app/invoice';

interface Props extends DispatchProp {
  key?: string;
  invoice: Invoice;
  products: Record<string, any>;
  productsOrder: string[];
  parentId?: string;
  onUpdate: Function;
  selectedProductsProps: Array<LineItem>;
  selectedLayout?: ProductGridType;
}

interface State {
  selectedProducts: Array<any>;
  orderedProductsList: Array<any>;
  multiSum: number;
  categoryFilter?: string;
}

class ProductSelectorList extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.handleProductSelect = this.handleProductSelect.bind(this);
    this.handleProductValueChange = this.handleProductValueChange.bind(this);
    this.pushBatchOfLineItems = this.pushBatchOfLineItems.bind(this);
    this.handleCategoryFilter = this.handleCategoryFilter.bind(this);
    this.handleCustomize = this.handleCustomize.bind(this);
    this.getLineItemById = this.getLineItemById.bind(this);
    this.isProductTypeValidForPartial = this.isProductTypeValidForPartial.bind(this);
    this.isProductUnitPartial = this.isProductUnitPartial.bind(this);

    this.state = {
      selectedProducts: props?.selectedProductsProps || props?.invoice?.lineItems || [],
      orderedProductsList: [],
      multiSum: 0,
      categoryFilter: '',
    };
  }

  componentDidMount(): void {
    const { products, productsOrder, onUpdate } = this.props;
    const { selectedProducts } = this.state;
    // update
    if (selectedProducts.length > 0) {
      _.map(products, (item: any) => {
        if (item.selectType === ProductSelectType.OBLIGATORY.key
        ) {
          this.handleProductSelect(item.id, item.selectType);
        }
      });

      // force refresh list of selected products when user switches between payment methods
      if (onUpdate) {
        onUpdate(selectedProducts);
      }
    } else { // new
      _.map(products, (item: any) => {
        if (item.selectType === ProductSelectType.OBLIGATORY.key
          || item.selectType === ProductSelectType.PRESELECTED.key
        ) {
          this.handleProductSelect(item.id, item.selectType);
        }
      });
    }
    this.handleProductOrder(productsOrder, products);
  }

  componentDidUpdate(prevProps): void {
    const { parentId, selectedProductsProps, invoice } = this.props;
    if (prevProps?.parentId !== parentId) {
      this.setState({ categoryFilter: parentId });
      this.handleCategoryFilter();
    }

    if (prevProps?.selectedProductsProps !== selectedProductsProps) {
      this.setState({ selectedProducts: selectedProductsProps });
    }

    if (prevProps?.invoice?.lineItems !== invoice?.lineItems) {
      this.setState({ selectedProducts: invoice.lineItems });
    }
  }

  handleProductOrder(productsOrder, productsData): void {
    const productsListOrdered = [] as any;

    productsOrder.forEach(item => {
      if (productsData[item]) {
        productsListOrdered.push(productsData[item]);
      }
    });

    this.setState({ orderedProductsList: productsListOrdered });
  }

  handleCategoryFilter(): void {
    const { products, productsOrder, parentId } = this.props;

    const productsData = _.reduce(
      products,
      (result, value: any, key) => {
        if ((parentId === '-1' || parentId == null) || value?.parentPath?.split(',').includes(parentId)) {
          result[key] = value;
        }
        return result;
      },
      {}); // this is needed for custom amount to work properly

    this.handleProductOrder(productsOrder, productsData);
  }

  // eslint-disable-next-line max-lines-per-function, max-statements
  handleProductSelect(itemId, selectType): void {
    const { selectedProducts } = this.state;
    const { products, onUpdate } = this.props;
    const newSelectedProducts = selectedProducts;
    const index = _.findIndex(selectedProducts, (item) => item.product && item.product.id === itemId);
    if (index >= 0) {
      if (selectType === ProductSelectType.OBLIGATORY.key) {
        return;
      }
      newSelectedProducts.splice(index, 1);
    } else {
      const product: any = _.find(products, (obj: any) => {
        return obj.id === itemId;
      });

      // For newly added obligatory custom service products we have
      // to specify default price set as quantity read from product state
      const quantity =
        // eslint-disable-next-line no-mixed-operators
        product && product.selectType === ProductSelectType.OBLIGATORY.key && product.type === ProductType.OTHER.key ||
          // eslint-disable-next-line no-mixed-operators
          product && product.selectType === ProductSelectType.PRESELECTED.key && product.type === ProductType.OTHER.key ||
          // eslint-disable-next-line no-mixed-operators
          product && product.type === ProductType.CUSTOM.key
          ? product.cost
          : 1;
      newSelectedProducts.push({
        product,
        qty: quantity,
        unit: product.unit
      });
    }
    this.setState({
      selectedProducts: newSelectedProducts,
    });

    if (onUpdate) {
      onUpdate(newSelectedProducts);
    }
  }

  pushBatchOfLineItems(lineItems): void {
    const { selectedProducts } = this.state;
    const { onUpdate } = this.props;
    const newSelectedProducts = selectedProducts
      .filter(item => item.product.type !== ProductType.ADD_MULTIPLE_LINE_ITEMS.key);

    newSelectedProducts.push(...lineItems);

    this.setState({
      selectedProducts: newSelectedProducts,
    });

    if (onUpdate) {
      onUpdate(newSelectedProducts);
    }
  }

  getLineItemById(id: string): LineItem {
    const { selectedProducts } = this.state;
    return _.find(selectedProducts, (item) => item?.product && item?.product?.id === id);
  }

  isProductTypeValidForPartial(itemId: string): boolean {
    const lineItem = this.getLineItemById(itemId);
    return (lineItem?.product?.type === ProductType.SERVICE.key || lineItem?.product?.type === ProductType.ITEM.key);
  }

  isProductUnitPartial(itemId: string): boolean {
    const lineItem = this.getLineItemById(itemId);
    return lineItem?.product?.unit === ProductUnit.PARTIAL.key;
  }

  isPartialCase(itemId: string): boolean {
    return this.isProductTypeValidForPartial(itemId) && this.isProductUnitPartial(itemId);
  }

  handleProductValueChange(itemId, value, description = null): void {
    const { selectedProducts } = this.state;
    const { onUpdate } = this.props;
    const lineItem = _.find(selectedProducts, (item) => item.product && item.product.id === itemId);
    lineItem.unit = lineItem.product.unit;
    // product.type === 'item' || 'service' && product.unit === 'partial'
    if (this.isPartialCase(itemId)) {
      lineItem.qty = round(value, 2);
      if (onUpdate && shouldUpdateProductQtyDecimalValue(value)) {
        onUpdate(selectedProducts);
      }
    } else {
      if (lineItem?.product?.type === ProductType.CUSTOM.key) {
        lineItem.qty = formatMoneyOnly(value);
      } else if (lineItem?.product?.type === ProductType.OTHER.key) {
        lineItem.qty = formatMoneyOnly(value);
        lineItem.description = description;
      } else {
        lineItem.qty = parseInt(value, 10);
      }

      if (onUpdate) {
        onUpdate(selectedProducts);
      }
    }
  }

  mapOrderedProducts(): React.ReactNode {
    const { selectedLayout } = this.props;
    const { selectedProducts, orderedProductsList } = this.state;

    const customLineItems = this.state.selectedProducts.filter(
      lineItem => lineItem.product.type === ProductType.ADD_MULTIPLE_LINE_ITEMS.key);
    const lineItemsSum = customLineItems.length
      ? [...customLineItems.map(newLineItem => newLineItem.qty)].reduce((a, b) => Number(a) + Number(b))
      : 0;
    const lineItemsQty = customLineItems && customLineItems.length;

    let gridType = selectedLayout;
    if (selectedLayout === ProductGridType.MediumGrid) {
      gridType = orderedProductsList.length > 15 ? ProductGridType.SmallGrid : ProductGridType.MediumGrid;
    }

    return orderedProductsList.map((item) => {
      // eslint-disable-next-line no-mixed-operators
      const product = _.find(selectedProducts, (o) => (o.productId === item.id || o.product && o.product.id === item.id));
      const selected = !!product;

      if (item.type === ProductType.ADD_MULTIPLE_LINE_ITEMS.key) {
        return null;
      }

      return (
        <ProductSelector
          id={item.id}
          key={item.id}
          product={item}
          selected={selected}
          lineItemsSum={lineItemsSum}
          lineItemsQty={lineItemsQty}
          qty={(product && product.qty) ? product.qty : ''}
          desc={product && product.description}
          multipleLineItems={this.state.multiSum}
          onToggle={(id) => this.handleProductSelect(id, item.selectType)}
          onChange={(id, value) => this.handleProductValueChange(id, value)}
          onCustomize={(id) => this.handleCustomize(item, id, selected, product)}
          gridType={gridType}
        />
      );
    })
  }

  // eslint-disable-next-line max-lines-per-function, max-params
  handleCustomize(item, id, selected, product): void {
    const { dispatch } = this.props;
    if (item?.type !== ProductType.OTHER.key) {
      return;
    }
    dispatch(
      openModal(
        ModalsConstants.LINE_ITEMS_CUSTOM_SERVICE,
        {
          selected: selected,
          isObligatory: item.selectType === ProductSelectType.OBLIGATORY.key,
          description: (product && product.description) || item.name,
          amount: (product && product.qty) || item.cost,
          addService: (description, amount) => {
            if (selected) {
              this.handleProductValueChange(id, amount, description);
            } else {
              this.handleProductSelect(id, item.selectType);
              this.handleProductValueChange(id, amount, description);
            }
          },
          removeService: () => {
            this.handleProductSelect(id, item.selectType);
          },
        }
      )
    );
  }

  render(): React.ReactElement {
    return (
      <Grid container spacing={3} key={`${this.props.key}-container`}>
        {this.mapOrderedProducts()}
      </Grid>
    )
  }
}

export default connect()(ProductSelectorList);
