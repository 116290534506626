import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Roles } from "../../constants/roles";
import { Company } from "../../types/Company";
import { GlobalState } from "../../types/GlobalState";
import { User } from "../../types/User";
import { IfUserRole } from "../ui/Visibility";
import Script from "react-load-script";

interface WootricSettings {
  account_token: string;
  created_at?: number | "";
  email: string;
  external_id: string;
  id: string;
  product_name: string;
  properties?: { [k: string]: string | number | undefined };
}

declare global {
  interface Window {
    wootric: any;
    wootricSettings: WootricSettings;
    wootric_no_surveyed_cookie?: boolean;
    wootric_survey_immediately?: boolean;
  }
}

type PropsFromState = Pick<GlobalState, "auth" | "companies" | "appSettings">;

interface Props extends PropsFromState {
  forceDisplay?: boolean;
}

interface State {
  launched?: boolean;
}

class WootricProvider extends React.Component<Props, State> {


  constructor(props: Props) {
    super(props);
    this.state = {};
    this.configure = this.configure.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
  }

  configure(): void {
    window.wootricSettings = this.getSettings();
    this.handleForceDisplay();
  }

  handleForceDisplay(): void {
    const { forceDisplay } = this.props;
    if (forceDisplay) {
      window.wootric_no_surveyed_cookie = true;
      window.wootric_survey_immediately = true;
    }
  }

  handleLoad(): void {
    const { launched } = this.state;
    if (launched || !this.canShow()) {
      return;
    }
    window.wootric("run");
  }

  canShow(): boolean {
    return !!this.getWootricAccountID() && !!this.getCompany() && !this.isImpersonatedUser();
  }

  getUser(): User | undefined {
    return this.props.auth?.me;
  }

  getWootricAccountID(): string | undefined {
    const { appSettings } = this.props;
    return appSettings?.settings?.config?.wootricAccountId;
  }

  isImpersonatedUser(): boolean {
    const { appSettings } = this.props;
    return !!appSettings?.settings?.impersonation;
  }

  getCompany(): Company | undefined {
    const { companies } = this.props;
    const companyId = this.getUser()?.companyId;
    return companyId ? companies.data?.[companyId] : undefined;
  }

  getParentCompany(): Company | undefined {
    const { companies } = this.props;
    const company = this.getCompany();
    return company?.parentCompanyId ? companies.data?.[company.parentCompanyId] : undefined;
  }

  getSettings(): WootricSettings {
    const user = this.getUser();
    const company = this.getCompany();
    const parentCompany = this.getParentCompany();
    return {
      account_token: this.getWootricAccountID() as string,
      external_id: "abc123",
      product_name: "RoadSync",
      created_at: user?.createdAt && moment(user.createdAt).unix(),
      id: user?.id as string,
      email: user?.email as string,
      properties: {
        company: company?.name,
        roles: user?.roles.map(role => Roles.getByKey(role).display).join(","),
        parent_company: parentCompany?.name,
      },
    };
  }

  render(): React.ReactElement {
    return (
      <IfUserRole includes={[Roles.COMPANY_ADMIN, Roles.EMPLOYEE, Roles.CLIENT_SUPPORT, Roles.ACCOUNTANT]}>
         <Script url="https://cdn.wootric.com/wootric-sdk.js" onCreate={this.configure} onLoad={this.handleLoad} />
      </IfUserRole>
    );
  }
}

/* istanbul ignore next */
const mapPropsToState: (state: GlobalState) => PropsFromState = ({ auth, companies, appSettings }) => ({ auth, companies, appSettings });
export default connect(mapPropsToState)(WootricProvider);
