import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Invoice } from '../../../../types/Invoice';
import { isSignatureEnabled } from '../../../../services/app/company';
import InvoiceFinalizeContainer from '../../../../components/invoice/InvoiceFinalizeContainer';
import InvoicePreparationStepContainer from './InvoicePreparationStepContainer';
import { GlobalState } from '../../../../types/GlobalState';
import { P, Grid } from '@roadsync/roadsync-ui';
import { InvoicePreparationStepProps } from '../../../../types/InvoicePreparationStepProps';
import FuelCardDetailsForm, { FuelCardDetailsFormData, fuelCardDetailsFormName } from '../../../../components/invoice/FuelCardDetailsForm';
import { Error } from '../../../../components/ui/Error';
import { LoadingContainer } from '../../../../components/ui/Visibility';
import { addSignature, payButtonPressed } from '../../../../services/api/invoices';
import { chargeFuelCard } from '../../../../services/api/deposits';
import { InvoicePaths } from '../../../../services/app/paths';
import InvoiceSignatureBox from '../../../../components/invoice/InvoiceSignatureBox';
import { Company } from '../../../../types/Company';

interface State {
    signatureUrl?: string;
    error?: string;
    disableSubmitButton?: boolean;
}

interface RouteParams {
    invoiceId: string;
}

type PropsFromState = Pick<GlobalState, "invoices" | "companies">;

interface Props extends InvoicePreparationStepProps, RouteComponentProps<RouteParams>, DispatchProp, PropsFromState { }

class FuelCardDetails extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadSignature = this.uploadSignature.bind(this);
        this.onSignatureUploaded = this.onSignatureUploaded.bind(this);
        this.state = {};
    }

    getInvoiceId(): string {
        const { match: { params: { invoiceId } } } = this.props;
        return invoiceId;
    }

    getInvoice(): Invoice | undefined {
        const { invoices } = this.props;
        return invoices?.data?.[this.getInvoiceId()];
    }

    getCompany(): Company | undefined {
        const { companies } = this.props;
        const invoice = this.getInvoice();
        const companyId = "string" === typeof invoice?.company ? invoice.company : invoice?.company?.id;
        return companyId ? companies?.data?.[companyId] : undefined;
    }

    isSignatureEnabled(): boolean {
        return isSignatureEnabled(this.getCompany());
    }

    showError(error = 'Something went wrong. Please try again'): void {
        this.setState({ error });
    }

    isSignatureOkay(): boolean {
        const { signatureUrl } = this.state;
        const hasError = this.isSignatureEnabled() && !signatureUrl;
        if (hasError) {
            this.showError('Please add your signature to continue');
        }
        return !hasError;
    }

    async handleSubmit(values: FuelCardDetailsFormData): Promise<void> {
        const { history } = this.props;
        if (!this.isSignatureOkay()) {
            return;
        }
        try {
            this.setState({ disableSubmitButton: true });
            payButtonPressed(this.getInvoice());
            await chargeFuelCard({ invoiceId: this.getInvoiceId(), ...values });
            this.setState({ disableSubmitButton: false });
            history.push(InvoicePaths.listUrl());
        } catch (e) {
            this.showError((e as any)?.message);
        }
    }

    async uploadSignature(base64Img: string): Promise<void> {
        try {
            const data = await addSignature(this.getInvoiceId(), base64Img);
            this.onSignatureUploaded(data.url);
        } catch (e) {
            this.showError((e as any)?.message);
        }
    }

    onSignatureUploaded(signatureUrl: string): void {
        this.setState({ signatureUrl });
    }

    getSignatureBox(): React.ReactNode {
        const { signatureUrl } = this.state;
        return <>
            {this.isSignatureEnabled() &&
                <InvoiceSignatureBox
                    invoiceId={this.getInvoiceId()}
                    onSignatureUploaded={this.onSignatureUploaded}
                    uploadSignature={this.uploadSignature}
                    signatureUrl={signatureUrl} />
            }
        </>;
    }

    render(): React.ReactElement {
        const { onCompletedStep } = this.props;
        const { error, disableSubmitButton } = this.state;
        const invoice = this.getInvoice();
        return (
            <InvoiceFinalizeContainer formName={fuelCardDetailsFormName} onCompletedStep={onCompletedStep} disableSubmitButton={disableSubmitButton}>
                <InvoicePreparationStepContainer>
                    <LoadingContainer loading={!invoice}>
                        <Grid container direction="column" spacing={2} wrap="nowrap">
                            {error && <Grid item><Error error={error} /></Grid>}
                            <Grid item>
                                <P variant="h4" gutterBottom>Please fill out the payment details below.</P>
                            </Grid>
                            <Grid item>
                                <Error error={"The Card Number is required."} />
                            </Grid>
                            <Grid item>
                                <FuelCardDetailsForm onSubmit={this.handleSubmit} paymentErrorCode={invoice?.paymentError}>
                                    {this.getSignatureBox()}
                                </FuelCardDetailsForm>
                            </Grid>
                        </Grid>
                    </LoadingContainer>
                </InvoicePreparationStepContainer>
            </InvoiceFinalizeContainer>
        );
    }
}

const mapStateToProps = ({ invoices, companies }: GlobalState): PropsFromState => ({ invoices, companies });
export default withRouter(connect(mapStateToProps)(FuelCardDetails));
