import React, { ReactNode } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { Box, Container, StyledComponentProps, withStyles } from '@material-ui/core';
import { Deposit, Company, Invoice, GlobalState } from '../../types';
import { PreparationSteps } from '../../constants/invoice';
import { Grid } from '@roadsync/roadsync-ui';
import ProductListTotal from '../product/ProductListTotal';
import { LineItem } from '../../types/LineItems';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { InvoiceEndpoints } from '../../services/api/endpoints/InvoiceEndpoints';
import InvoiceCheckoutTopContent from './InvoiceCheckoutTopContent';
import { getPriceBreakdown, isRemoteCheckout } from '../../services/app/invoice';
import InvoicePreparationStepContainer from '../../containers/invoice/preparation/steps/InvoicePreparationStepContainer';
import styles from "./InvoiceFinalizeContainer.css";
import { InvoicePaths } from '../../services/app/paths';
import { CardDetailsFormData } from "../../containers/invoice/preparation/steps/CardDetailsForm";
import SendInvoiceReceiptViaSMSToPayer from './SendInvoiceReceiptViaSMSToPayer';
import { isReducePaperCheckFlowEnabled } from '../../services/app/company';

interface OwnProps {
    children?: React.ReactNode;
    onCompletedStep?: (nextStep: PreparationSteps) => void;
    formName: string;
    handleSubmit?: (event: MouseEvent, values?: CardDetailsFormData) => void;
    fallback?: boolean;
    disableChargeBtnComdataV2?: boolean;
    finalForm?: boolean;
    disableSubmitButton?: boolean;
    hideSubmitButton?: boolean;
}

interface RouteParams {
    invoiceId: string;
}

interface Props extends PropsFromState, DispatchProp, RouteComponentProps<RouteParams>, OwnProps, StyledComponentProps { }

class InvoiceFinalizeContainer extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.handlePrintReceipt = this.handlePrintReceipt.bind(this);
        this.changePaymentMethod = this.changePaymentMethod.bind(this);
        this.onDone = this.onDone.bind(this);
        this.getProductList = this.getProductList.bind(this);
        this.getProdcutListFallback = this.getProdcutListFallback.bind(this);
    }

    getSelectedProducts(): LineItem[] {
        const invoice = this.getInvoice();
        return invoice.lineItems || [];
    }

    getInvoice(): Invoice {
        const { invoices } = this.props;
        const invoiceId = this.getInvoiceId();
        return (invoiceId && invoices.data?.[invoiceId]) || {} as Invoice;
    }

    getInvoiceId(): string | undefined {
        return this.props.invoiceId;
    }

    getCompany(): Company {
        const invoice = this.getInvoice();
        const { companies } = this.props;
        return companies?.data?.[invoice.company as string] as Company;
    }

    handlePrintReceipt(): void {
        this.openPrintInvoiceWindow();
    }

    openPrintInvoiceWindow(): void {
        window.open(this.getPrintInvoiceURL(), '_blank');
    }

    getPrintInvoiceURL(): string {
        const invoice = this.getInvoice();
        return isRemoteCheckout(invoice)
            ? InvoiceEndpoints.printInvoiceReceiptPublic(invoice.token ?? '')
            : InvoiceEndpoints.printInvoiceReceipt(invoice.id);
    }

    getDeposit(): Deposit {
        return this.props.deposits?.data[this.getInvoice().id];
    }

    changePaymentMethod(): void {
        const { onCompletedStep } = this.props;
        if (onCompletedStep) {
            onCompletedStep(PreparationSteps.PAYMENT_METHOD);
        }
    }

    onDone(selectedProducts: LineItem[], exitNow?: boolean, sendDetails?: boolean): void {
        // Nothing will have changed on this screen, so there's no need to do a real save
        const { history, onCompletedStep } = this.props;
        if (sendDetails && onCompletedStep) {
            onCompletedStep(PreparationSteps.SEND_INVOICE_DETAILS_TO_PAYER);
            return;
        }
        if (exitNow) {
            history.push(InvoicePaths.listUrl());
        }
    }

    getProductList(): ReactNode {
        const { formName, handleSubmit, disableChargeBtnComdataV2, finalForm, disableSubmitButton, hideSubmitButton } = this.props;
        const invoice = this.getInvoice();
        const company = this.getCompany();
        return <ProductListTotal
            selectedProducts={this.getSelectedProducts()}
            invoice={invoice}
            onDone={this.onDone}
            finalizeInvoice={true}
            formName={formName}
            updateConvFee={(): void => void (0)}
            company={company}
            handlePrintReceipt={this.handlePrintReceipt}
            deposit={this.getDeposit()}
            changePaymentMethod={this.changePaymentMethod}
            price={getPriceBreakdown(invoice)}
            handleSubmit={handleSubmit}
            disableChargeBtnComdataV2={disableChargeBtnComdataV2}
            finalForm={finalForm}
            disableSubmitButton={disableSubmitButton}
            hideSubmitButton={hideSubmitButton}
            shouldEnableAchForInvoice={invoice?.paymentSettings?.isAchEnabled}
        />
    }

    checkoutFallbackBtnLabel(): string {
        const company = this.getCompany();
        return isReducePaperCheckFlowEnabled(company) ? "Confirm & Charge" : "The Express Code has been registered";
    }

    getProdcutListFallback(): ReactNode {
        const { formName, handleSubmit, disableSubmitButton } = this.props;
        const invoice = this.getInvoice();
        const company = this.getCompany();
        return <ProductListTotal
            selectedProducts={this.getSelectedProducts()}
            invoice={invoice}
            onDone={this.onDone}
            finalizeInvoice={true}
            formName={formName}
            updateConvFee={(): void => void (0)}
            company={company}
            handlePrintReceipt={this.handlePrintReceipt}
            deposit={this.getDeposit()}
            changePaymentMethod={this.changePaymentMethod}
            price={getPriceBreakdown(invoice)}
            handleSubmit={handleSubmit}
            checkoutBtnLabel={this.checkoutFallbackBtnLabel()}
            disableSubmitButton={disableSubmitButton}
            infoBlock={
                <Grid item>
                    <Box mt={1} p={2} fontSize={14}>After the check is registered, please click the above button so we can continue working on this for you!</Box>
                </Grid>}
            shouldEnableAchForInvoice={!invoice?.paymentSettings?.isAchEnabled}
        />
    }

    render(): React.ReactElement {
        const { children, classes, fallback } = this.props;
        const invoice = this.getInvoice();
        return (
            <Box id="invoice-finalize-container" className={classes?.root} >
                <Grid container spacing={2} className={classes?.container}>
                    <Grid item lg={7} md={6} sm={12} xs={12} className={classes?.contentColumn}>
                        <Container maxWidth="sm">
                            <InvoicePreparationStepContainer title="Confirm Invoice & Checkout">
                                <InvoiceCheckoutTopContent invoice={invoice} />
                                {children}
                            </InvoicePreparationStepContainer>
                        </Container>
                    </Grid>
                    <Grid item lg={5} md={6} sm={12} xs={12} className={classes?.controlsColumn}>
                        {fallback && this.getProdcutListFallback()}
                        {!fallback && this.getProductList()}
                    </Grid>
                </Grid>
                <SendInvoiceReceiptViaSMSToPayer invoice={invoice} token={invoice.token} />
            </Box>
        );
    }
}

type PropsFromState = Pick<GlobalState, "companies" | "deposits" | "invoices" | "products"> & {
    invoiceId: string;
}

const mapStateToProps = (
    { invoices, products, deposits, companies }: GlobalState,
    { match: { params: { invoiceId } } }: RouteComponentProps<RouteParams>
): PropsFromState => ({ invoices, deposits, products, companies, invoiceId });

export default withRouter(withStyles(styles)(connect(mapStateToProps)(InvoiceFinalizeContainer)));
