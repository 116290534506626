import React from "react";
import { Box, Grid, Paper, Tooltip } from '@material-ui/core';
import { LargeButton } from './buttons/LargeButton';
import { Alert } from '@material-ui/lab';
import styles from "./SignatureBox.css";

interface SignatureBoxProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  signatureUrl?: string;
}

// eslint-disable-next-line max-lines-per-function
const SignatureBox: React.FC<SignatureBoxProps> = (props: SignatureBoxProps) => {
  const { onClick, signatureUrl } = props;
  const classes = styles();
  return (
    <Grid container spacing={2} alignItems="center" data-testid='signature-box'>
      <Grid item xs={12} sm={8} md={6} lg={8}>
        <Paper id="signature-box">
          <Tooltip title="Signature">
            <Box display="flex" alignItems="center" justifyContent="center" className={classes?.signature} onClick={onClick}>
              {signatureUrl && <img src={signatureUrl} alt="signature" id="signature-image" />}
            </Box>
          </Tooltip>
        </Paper>
      </Grid>
      {!signatureUrl &&
        <Grid item xs={12} sm={4} md={6} lg={4}>
          <LargeButton onClick={onClick} fullWidth id="add-signature-btn" className={classes?.signatureBtn}>Add Signature</LargeButton>
        </Grid>
      }
      {signatureUrl &&
        <Grid item xs={12} sm={4} md={6} lg={4} className={classes?.iconWrapper}>
          <Alert severity="success" className={classes?.icon} id="signature-complete-icon" />
        </Grid>
      }
    </Grid>
  );
}

export default SignatureBox;
