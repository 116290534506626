import React from "react";
import { connect, DispatchProp } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CheckType, CheckTypes as Types } from "../../../../constants/deposit";
import { depositCheckCreateOrReuse, unsavedProgress } from "../../../../actions/deposits";
import { Invoice, GlobalState, OnCompletedStepProps, Company, Location } from "../../../../types";
import { sendNotificationToPayer } from "../../../../actions/invoices";
import { PayerMessages } from "../../../../constants/payerMessages";
import { PreparationSteps } from "../../../../constants/invoice";
import { isReducePaperCheckFlowEnabled, isSkipCheckDetailsScreenEnabled } from "../../../../services/app/company";
import { Error } from "../../../../components/ui/Error";
import PaymentMethodListButton from "../../../../components/app/PaymentMethodListButton";
import iconFleetCard from "../../../../assets/img/icons/v2/fleet-card.svg";
import { Grid, List } from "@material-ui/core";
import InvoicePreparationStepContainer from "./InvoicePreparationStepContainer";
import { NoCheckRefundsText } from "../../../../components/ui/NoCheckRefundsText";
import { FullScreenLoader } from "@roadsync/roadsync-ui";
import { isEqual } from "lodash";
import { CheckIntroductionText } from "../../../../components/ui/CheckIntroductionText";

interface State {
  selectedType: string;
  error?: string;
  loading: boolean;
}

type PropsFromState = Pick<GlobalState, "invoices" | "deposits" | "companies" | "locations" | "auth" | "publicData">;

interface RouteParams {
  invoiceId: string;
}

interface OwnProps extends OnCompletedStepProps {
  pageHeader: string;
}

interface Props extends OwnProps, RouteComponentProps<RouteParams>, PropsFromState, DispatchProp { }

class SelectFleetCardType extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.handleSelectCheckType = this.handleSelectCheckType.bind(this);
    this.updateInvoiceCheckType = this.updateInvoiceCheckType.bind(this);
    this.state = { selectedType: Types.COMCHEK.key, loading: true };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }

  getLocationId(): string {
    const invoice = this.getInvoice();
    if (invoice) {
      return invoice?.locationId
        ? invoice?.locationId
        : "string" === typeof invoice?.location
          ? invoice?.location
          : invoice?.location?.id;
    }
    return "";
  }

  getLocation(): Location | undefined {
    const { locations: { data } } = this.props;
    return data?.[this.getLocationId()];
  }

  isComdataApiLocationIdSet(): boolean {
    const location = this.getLocation();
    const comdataApiLocationId = location?.comdataApiLocationId;
    return typeof comdataApiLocationId === "string" && comdataApiLocationId?.length > 0;
  }

  isComdataApiFlowEnabledAndConfigured(): boolean {
    const isComdataApiLocationIdSet = this.isComdataApiLocationIdSet();
    return isComdataApiLocationIdSet;
  }

  comdataRegisterCheckToCard(): void {
    const { onCompletedStep } = this.props;
    onCompletedStep(PreparationSteps.COMDATA_CONFIRM_REGISTER_CHECK_TO_CARD);
  }

  getInvoiceId(): string {
    const { match: { params: { invoiceId } } } = this.props;
    return invoiceId;
  }

  getInvoice(): Invoice | undefined {
    const { invoices } = this.props;
    return invoices?.data?.[this.getInvoiceId()];
  }

  getCompany(): Company | undefined {
    const { companies } = this.props;
    const invoice = this.getInvoice();
    const companyId = "string" === typeof invoice?.company ? invoice.company : invoice?.company?.id;
    return companyId ? companies?.data?.[companyId] : undefined;
  }

  async sendNotificationToPayer(checkNumber?: string, checkType?: string): Promise<void> {
    if (isSkipCheckDetailsScreenEnabled(this.getCompany())) {
      return;
    }
    const { dispatch } = this.props;
    const invoice = this.getInvoice();
    await dispatch<any>(sendNotificationToPayer(
      this.getInvoiceId(), invoice?.payerPhone, invoice?.payerEmail, PayerMessages.CHECK_DETAILS, checkNumber, checkType
    ));
  }

  getNextStep(): PreparationSteps {
    const { selectedType } = this.state;
    const company = this.getCompany();

    if (isSkipCheckDetailsScreenEnabled(company)) {
      return PreparationSteps.LINE_ITEMS;
    }

    if (this.isComdataApiFlowEnabledAndConfigured() && selectedType === Types.COMCHEKV2.key && isReducePaperCheckFlowEnabled(company)) {
      return PreparationSteps.LINE_ITEMS;
    }

    return this.isComdataApiFlowEnabledAndConfigured() && selectedType === Types.COMCHEKV2.key && !isReducePaperCheckFlowEnabled(company)
      ? PreparationSteps.COMDATA_CONFIRM_REGISTER_CHECK_TO_CARD
      : PreparationSteps.FLEET_CARD_CHECK_NUMBER;
  }

  onError(e?: { message?: string }): void {
    this.setState({ error: e?.message || "Unable to process your request." });
  }

  async updateInvoiceCheckType(): Promise<void> {
    const { onCompletedStep, dispatch } = this.props;
    const { selectedType } = this.state;
    const company = this.getCompany();
    const invoice = this.getInvoice();
    this.setState({ loading: true });
    dispatch(unsavedProgress(selectedType));

    if (this.isComdataApiFlowEnabledAndConfigured() && selectedType === Types.COMCHEKV2.key && !isReducePaperCheckFlowEnabled(company)) {
      this.comdataRegisterCheckToCard();
      return;
    }

    try {
      const action = await dispatch<any>(depositCheckCreateOrReuse(this.getInvoiceId(), selectedType, invoice?.payerState, invoice?.payerIdentifier));
      await this.sendNotificationToPayer(action?.deposit?.checkNumber, action?.deposit?.checkType);
      onCompletedStep(this.getNextStep());
    } catch (e) {
      this.onError(e as any);
      this.setState({ loading: false });
    }
  }

  handleSelectCheckType(selectedType: string): void {
    this.setState({ selectedType }, this.updateInvoiceCheckType);
  }

  getCheckTypes(): CheckType[] {
    const invoice = this.getInvoice();
    const company = this.getCompany();
    const comdataExpressCodeLabel = isReducePaperCheckFlowEnabled(company) ? Types.COMDATA_EXPRESS_CODE : Types.COMCHEKV2;

    return this.isComdataApiFlowEnabledAndConfigured() && invoice?.subtype !== Types.COMCHEK.key // not v1 and not fallback
      ? [comdataExpressCodeLabel, Types.EFS, Types.TCHECK]
      : [Types.COMCHEK, Types.EFS, Types.TCHECK];
  }

  render(): React.ReactElement {
    const { pageHeader } = this.props;
    const { error, loading } = this.state;
    const CheckTypes = this.getCheckTypes();
    const company = this.getCompany();
    const displayReducePaperCheckFlowText = isReducePaperCheckFlowEnabled(company);
    return (
      <InvoicePreparationStepContainer title={pageHeader}>
        <Grid container direction="column" spacing={2}>
          {error && <Grid item><Error error={error} /></Grid>}
          <FullScreenLoader show={loading} />
          {displayReducePaperCheckFlowText && <Grid item><CheckIntroductionText /></Grid>}
          <Grid item><NoCheckRefundsText /></Grid>
          <Grid item>
            <List>
              {CheckTypes.map(t => (
                <PaymentMethodListButton
                  key={t.key}
                  id={`select-check-btn--${t.key}`}
                  icon={<img src={iconFleetCard} alt={`${t.display} icon`} />}
                  onClick={(): void => this.handleSelectCheckType(t.key)}
                  text={t.display}
                />
              ))}
            </List>
          </Grid>
        </Grid>
      </InvoicePreparationStepContainer>
    );
  }
}

const mapStateToProps = ({ invoices, deposits, companies, locations, auth, publicData }): PropsFromState => ({ invoices, deposits, companies, locations, auth, publicData });
export default withRouter(connect(mapStateToProps)(SelectFleetCardType));
